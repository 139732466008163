import React from "react";
import { Stack } from "@mui/system";
import "./YouTubeVideo.css";

const YouTubeVideo = ({ videoId, width = "100%", iframeRef, isMuted }) => {
  
  const paddingTop =
    width === "100%" ? "56.25%" : width === "60%" ? "33.7%" : "56.25%";
  const marginTop = width === "100%" ? "-60px" : "10px";
  return (
    <Stack
      sx={{
        marginTop: marginTop,
        width: width,
        position: "relative",
        paddingTop: paddingTop,
      }}
    >
      <iframe
        ref={iframeRef}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=${
          isMuted ? 1 : 0
        }&loop=1&playlist=${videoId}&fs=0&disablekb=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3&cc_load_policy=0&playsinline=1`}
        style={{
          border: "none",
          position: "absolute",
          top: 0,
          left: 0,

          width: "100%",
          height: "100%",
        }}
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="video"
        
      ></iframe>
    </Stack>
  );
};

export default YouTubeVideo;
