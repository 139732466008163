/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  productPageData: [],
  productDetailsPageData: [],
  cardsColors:{},
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function productPageReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.PRODUCT_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.PRODUCT_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        productPageData: payload?.result?.data,
        cardsColors:payload?.result?.colors
      };
    }
    case Types.PRODUCT_DETAILS_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        productDetailsPageData: payload?.result?.data,
        cardsColors:payload?.result?.colors
      };
    }
    default: {
      return state;
    }
  }
}
