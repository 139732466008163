import { reduxRequest } from "../utils/reduxRequest";
import { ProductDataLoading, ProductDataSuccess, ProductDetailsDataSuccess } from "./Actions";

export const productPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductDataLoading,
    successFunction: ProductDataSuccess,
    url: "/product",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const productDetailsPageDataRequest = ({ id, ...variables }) => {
  const requestData = {
    isLoading: ProductDataLoading,
    successFunction: ProductDetailsDataSuccess,
    url: `/product/${id}`,
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
