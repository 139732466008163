import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Used for scrolling to top when navigate from page to another

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);
  return null;
};
