import CardMedia from "@mui/material/CardMedia";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

export const cheakProductType = (
  type,
  isActive,
  subCateogryType,
  isDetails
) => {
  let animation = ``;
  let style;
  let logoHeight = "";
  let bgcolor = { activeBg: null, nonActive: null };
  let paddingBottom = "";
  if (type?.toLowerCase() == "cones") {
    animation = {
      transform:
        subCateogryType == "Indulgence"
          ? "translate(-25%, -24%) rotate(18deg)"
          : "translate(-23%, -22%) rotate(16deg)",
      width: isActive ? "100%" : "80%",
    };
    style = {
      transform: isActive
        ? "translate(22%, -22%) rotate(-18deg)"
        : "translate(42%, -22%) rotate(-18deg)",
      width: isActive ? "100%" : "80%",
      mb: "-250px",
    };
    bgcolor = { activeBg: "#FCF1B6", nonActive: "#FFD9D9" };
    paddingBottom = "0";
  } else if (type?.toLowerCase() == "sticks") {
    animation = {
      transform: "translate(-3%, -29%) rotate(15deg)",
    };
    style = {
      transform: "translate(20%, -29%) rotate(-10deg)",
      mb: isActive ? "-35%" : "-38%",
    };
    logoHeight = "120px";
    bgcolor = { activeBg: "#D4F4D4", nonActive: "#E5DFFE" };
    paddingBottom = "0";
  } else if (type?.toLowerCase() == "cups") {
    animation = {
      display: "none !important",
    };
    style = {
      transform: "translate(3%, -34%)",
      // mb: isActive ? '-27%' : '-35px',
      width: isActive ? "230px" : "230px",
    };
    bgcolor = { activeBg: "#FFDDBD", nonActive: "#CEEBF5" };
    paddingBottom = "40px";
  } else if (type?.toLowerCase() == "sandwitches") {
    animation = {
      transform: "translate(-30%, -8%) rotate(22deg)",
      // width: isActive ? "300px" : "200px",
      // height: isActive ? '200px' : '130px',
      // mb: isActive ? "40%" : '65%',
      // transition: "0.1s",
    };
    style = {
      transform: "translate(32%, -18%) rotate(-22deg)",
      width: isActive ? "400px" : "400px",
      // height: isActive ? '200px' : '120px',
    };
    bgcolor = { activeBg: "#E5DFFE", nonActive: "#D4F4D4" };
    paddingBottom = "100px";
  } else if (type?.toLowerCase() == "tubs") {
    animation = {
      display: "none !important",
    };
    style = {
      transform:
        subCateogryType == "Indulgence"
          ? "translate(0, -34%)"
          : isActive
          ? "translate(0, -31%)"
          : "translate(-3%, -37%)",
      width:
        subCateogryType == "Indulgence" ? "100%" : isActive ? "450px" : "280px",
      mb:
        subCateogryType == "Indulgence"
          ? "-100px"
          : isActive
          ? "-270px"
          : "-133px",
      // mb: (subCateogryType == 'Indulgence') ? "-34%" : isActive ? "-57%" : '-50%',
      // top: "35%",
      // left: "50%",
    };
    bgcolor = { activeBg: "#FFD9D9", nonActive: "#FCF1B6" };
    paddingBottom = "40px";
  }

  return { animation, style, logoHeight, bgcolor, paddingBottom };
};
