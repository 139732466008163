import { Box, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useThemePalette } from "../../Theme/useThemePalatte";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { contactUsDataRequest } from "../../services/modules/contactUs";

export default function Layout({ children }) {
  const { mainBg, mainLightColor } = useThemePalette();
  const dispatch = useDispatch();

  const { contactUsPageData } = useSelector(state => state.contactUs)
  useEffect(() => {
    const requestData = {
      body: { "jsonrpc": "2.0", "params": {} }
    };
    dispatch(contactUsDataRequest({ ...requestData }));
  }, []);
  return (
    <Stack
      direction="column"
      width="100%"
      style={{ background: mainBg }}
    >
      <Navbar />
      {children}
      <Footer contactUsPageData={contactUsPageData} />
    </Stack>
  );
}
