import { reduxRequest } from "../utils/reduxRequest";
import { BlogDataLoading, BlogDataSuccess, BlogdDetailsDataSuccess } from "./Actions";

export const blogPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: BlogDataLoading,
    successFunction: BlogDataSuccess,
    url: "/blog",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const blogDetailsPageDataRequest = ({ id, ...variables }) => {
  const requestData = {
    isLoading: BlogDataLoading,
    successFunction: BlogdDetailsDataSuccess,
    url: `/blog/${id}`,
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
