import { Box, Stack, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SectionTitle from "../Atoms/SectionTitle";
import Accordions from "../Organisms/Careers/accordian";
import PagesSkeleton from "../Molecules/PagesSkeleton";
import { useThemePalette } from "../Theme/useThemePalatte";
import Motion from "../Atoms/SharedComponent/Motion";
import { fadeInLeft } from "../Organisms/Home/MotionDirections";
import JobForm from "../Organisms/Careers/jobForm";

export default function CareersTemplate({ careerPageData }) {
  const { mainDarkColor } = useThemePalette();
  return (
    <Stack>
      <PagesSkeleton title="CAREERS" />
      <Container maxWidth="xl" >
        <Stack sx={{ mb: '5%' }}>
          <Typography
            align="center"
            sx={{
              fontSize: { lg: '30px', md: "20px", sm: '16px', xs: '13px' },
              color: "#00000099",
              fontWeight: 600,
              // lineHeight: "25px",
            }}
          >
            {careerPageData?.header?.description && careerPageData?.header?.description}
          </Typography>
        </Stack>
        <Motion {...fadeInLeft({ width: "100%", duration: "1.9" })}>
          <Stack flexDirection={{ md: "row", xs: "column-reverse" }} gap={4}>
            <Stack width={{ md: "48%", xs: "100%" }} gap={5}>
              <Stack sx={{ my: 1 }}>
                <SectionTitle
                  color={mainDarkColor}
                  title={careerPageData?.header?.title}
                  fontSize={{ lg: "1.8rem" , md: "20px", sm: '18px', xs: '15px' }}
                  textTransform="capitalize"
                />
              </Stack>
              <picture style={{ display: "flex", width: "100%", height: "100%" }}>
                {careerPageData?.header?.image?.slice(0, 3)?.map((blogImg) => (
                  <source
                    key={blogImg?.size}
                    media={`(min-width:${blogImg?.size}px)`}
                    srcSet={blogImg?.image_url}
                  />
                ))}
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={careerPageData?.header?.image?.filter((img) => img?.size == 1920)[0]?.image_url}
                  alt=""
                />
              </picture>

              <SectionTitle
                color={mainDarkColor}
                title={"If you want to join us , Fill out this form now"}
                fontSize={{ md: "1.8rem", sm: '18px', xs: '15px' }}
              />
              <Motion
                {...fadeInLeft({
                  width: "100%",
                  duration: "1.9",
                  delay: "0.5",
                })}
              >
                <JobForm />
              </Motion>
            </Stack>
            <Box width={{ md: "48%", xs: "100%" }} sx={{ my: 1 }}>
              <Stack sx={{ my: 3 }}>
                <SectionTitle
                  title={"Vacancy Jobs"}
                  fontSize={{ lg: "1.8rem" , md: "20px", sm: '18px', xs: '15px' }}
                  color={mainDarkColor}
                  textTransform="capitalize"
                  textAlign={{ md: 'start', xs: 'center' }}
                />
              </Stack>
              <Stack>
                {careerPageData?.jobs && <Accordions jobs={careerPageData?.jobs} />}
              </Stack>
            </Box>
          </Stack>
        </Motion>
      </Container>
    </Stack>
  );
}
