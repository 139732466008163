import React, { useState } from "react";
import caynBg from "../../Assets/Images/caynBg.png";
import BlogCard from "../../Molecules/Blog/Card";
import SkeletonComponent from "../../Molecules/SkeletonComponent";
import BlogData from "../../Molecules/Blog/Data";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import chocolate1 from "../../Assets/Videos/chocolate1.webm";

export default function Blog({ blogs, mainBlog }) {
  const [{ blogCardData, blogData }, setBlogCardData] = useState(
    require("../../db/home-page.json")
  );

  return (
    <>
      <Box
        position="relative"
        // sx={{ bgcolor: "primary.main !important" }}
      >
        <Stack
          position="absolute"
          top={{ lg: "-135px", xs: "-2px" }}
          width="100%"
          zIndex={1}
        >
          <video muted width="100%" autoPlay>
            <source src={chocolate1} />
          </video>
        </Stack>
      </Box>

      <SkeletonComponent
        title="Blogs"
        titlePt={{ lg: 0, sm: "5rem", xs: "2rem" }}
        // boxShadow='4px 2px 0px #00BCD4'
        boxShadow={{
          sm: "4px 4px 0px rgba(0, 0, 0, 0.15)",
          xs: "2px 2px 0px rgba(0, 0, 0, 0.15)",
        }}
        fontSize={{ md: "70px", xs: "22px" }}
        leftSide={<BlogData blogData={blogData} mainBlog={mainBlog} />}
        bgImg={caynBg}
        RightSide={<BlogCard blogCardData={blogCardData} blogs={blogs} />}
        chocolate
        // pb="4rem"
        alignItems="start"
        leftDuration="1.9"
        rightDuration="2"
        leftSideWidth={{ lg: "35%", md: "30%", sm: "50%", xs: "47%" }}
        rightSideWidth="40%"
        containerPt={{ xs: "6rem", sm: "10rem" }}
        displayShowMoreBtn={true}
        showMorePath={"/blogs"}
        showMoreTitle={"Show More"}
      />
    </>
  );
}
