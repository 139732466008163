import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";

export default function AvatarData({
  avatarImg,
  name,
  avatarHeight,
  avatarWidth,
  nameColor = "white",
  marginLeft = "12px",
  fontSize = "18px",
}) {
  return (
    <>
      <Grid container sx={{ width: "fit-content" }} alignItems="center">
        {/* <Grid item>
          <Avatar
            alt="Remy alioSharp"
            src={avatarImg}
            sx={{ height: avatarHeight, width: avatarWidth }}
          />
        </Grid>
        <Grid item>
          <Typography
            marginLeft={marginLeft}
            color={nameColor}
            fontWeight="700"
            fontSize={fontSize}
            component={"span"}
          >
            {name}
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
