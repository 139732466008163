import Types from "./Types";

export const GalleryDataSuccess = (payload) => ({
  type: Types.GALLERY_PAGE_DATA_SUCCESS,
  payload,
});

export const GalleryDataLoading = (payload) => ({
  type: Types.GALLERY_PAGE_DATA_LOADING,
  payload,
});
