import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import aboutUsBg from "../../Assets/Images/aboutUsBg.png";
import CustomButton from "../../Atoms/CustomButton";
import SectionTitle from "../../Atoms/SectionTitle";
import Motion from "../../Atoms/SharedComponent/Motion";
import PagesContainer from "../../Molecules/PagesContainer";
import { fadeInLeft } from "./MotionDirections";
import ice from "../../Assets/Videos/ice.webm";
import milkFold1280 from "../../Assets/Videos/milkFold1280.webm";
import milkFoldMobile4 from "../../Assets/Videos/milkfold33.webm";
import milkFoldSmall from "../../Assets/Videos/milkFoldSmall.webm";
import ice600 from "../../Assets/Videos/ice600.webm";
import ice7 from "../../Assets/Videos/ice7.webm";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function About({ aboutData }) {
  const [{ about }, setAbout] = useState(require("../../db/home-page.json"));
  const { darkGrayText, gray8 } = useThemePalette();

  return (
    <PagesContainer>
      <Grid
        container
        paddingBottom={{ lg: "10rem", md: "8rem" }}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        height={{ lg: "1100px", md: "750px" }}
      >
        <Grid
          item
          lg={5.5}
          md={8}
          xs={9}
          // position="absolute"
          zIndex={3}
        >
          <Motion {...fadeInLeft({ width: "100%", duration: "1.9" })}>
            <Stack
              padding={{
                lg: "140px 40px 50px",
                md: "100px 40px 50px",
                sm: "100px 16px",
                xs: "20px 16px",
              }}
            >
              <SectionTitle
                boxShadow={{
                  sm: "4px 4px 0px rgba(0, 0, 0, 0.15)",
                  xs: "2px 2px 0px rgba(0, 0, 0, 0.15)",
                }}
                fontSize={{ md: "70px", xs: "22px" }}
                title="About Us"
              />
              <Typography
                paddingY="30px"
                fontSize={{ lg: "27px", md: "23px", xs: "17px" }}
                color={gray8}
                fontFamily="Inter"
                // dangerouslySetInnerHTML={{ __html: aboutData?.description }}
              >
                {aboutData?.description}
                {/* Welcome to Fusteka Ice Cream! We are proud to have been serving our delicious ice cream since 2001. Our passion for creating the highest quality ice cream using only the freshest ingredients has been our guiding principle from the beginning. <br />
                Our dedication to excellence is
                evident in every scoop of our
                creamy, rich ice cream.We offer a wide range of flavors to suitany taste bud, from classic favorites like vanilla
                and chocolate to more
                unique, adventurous
                options like Man
                El Sama and
                Pistachio with
                pieces. */}
              </Typography>
              <Box>
                <CustomButton
                  title={"SHOW MORE"}
                  width={{ md: "200px", sm: "100px" }}
                  path="/about"
                  xsMt="0"
                  fontSize={{ md: "16px", xs: "12px" }}
                />
              </Box>
            </Stack>
          </Motion>
        </Grid>

        <Grid
          display={{ lg: "block", xs: "none" }}
          item
          position="absolute"
          top={{ lg: "15%" }}
          right="0"
          // left={{ lg: 0 }}
          zIndex={"0"}
          className="milk-video"
        >
          <Box
            component={"video"}
            muted
            width={{ lg: "100%", md: "101%" }}
            autoPlay
          >
            <source src={milkFold1280} />
          </Box>
        </Grid>

        <Grid
          display={{ lg: "none", md: "block", xs: "none" }}
          item
          position="absolute"
          top={{ lg: "67px" }}
          right="0"
          // left={{ lg: 0 }}
          zIndex={"0"}
          className="milk-video"
        >
          <Box
            component={"video"}
            muted
            width={{ lg: "100%", md: "101%" }}
            autoPlay
          >
            <source src={milkFoldSmall} />
          </Box>
        </Grid>

        <Grid
          display={{ md: "none", xs: "block" }}
          item
          position="absolute"
          //top={{ xs: '67%' }}
          right="0"
          // zIndex={"0"}
          className="milk-video"
        >
          <Box component="video" muted width={{ lg: "100%" }} autoPlay>
            <source src={milkFoldMobile4} />
          </Box>
        </Grid>

        <Grid
          item
          position="absolute"
          sx={{ transform: "translateY(50%)" }}
          bottom={{ xs: "-35px" }}
          // left={"-4px"}
          display={{ sm: "none", xs: "block" }}
          right={"0"}
          className="700"
        >
          <video muted width="100%" autoPlay loop>
            <source src={ice600} />
          </video>
        </Grid>

        <Grid
          item
          position="absolute"
          sx={{ transform: "translateY(50%)" }}
          bottom={{ md: "50px", xs: "1px" }}
          // left={"-4px"}
          display={{ md: "none", sm: "block", xs: "none" }}
          //  lg: 'none', md: 'block', xs: 'none'
          right={"0"}
          className="900"
        >
          <video muted width="100%" autoPlay loop>
            <source src={ice7} />
          </video>
        </Grid>

        <Grid
          item
          position="absolute"
          sx={{ transform: "translateY(50%)" }}
          bottom={{ md: "50px", xs: "12px" }}
          left={"-4px"}
          display={{ lg: "block", xs: "none" }}
          right={"0"}
          className="900"
        >
          <video muted width="100%" autoPlay loop>
            <source src={ice} />
          </video>
        </Grid>
      </Grid>
    </PagesContainer>
  );
}
