import React from 'react'
import DetailsComponent from '../Atoms/SharedComponent/DetailsComponent';


export default function RecipesDetailsTemplate({
    data,
    product,
    moreData,
    recipesDetailsPageData,
    moreRecipes
}) {
    return <>
        <DetailsComponent
            data={data}
            product={product}
            moreData={moreData}
            moreDataa={moreRecipes}
        />
    </>
}
