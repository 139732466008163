import { reduxRequest } from "../utils/reduxRequest";
import { GalleryDataLoading, GalleryDataSuccess } from "./Actions";

export const galleryPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GalleryDataLoading,
    successFunction: GalleryDataSuccess,
    url: "/gallery",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
