import React from "react";
import About from "../Organisms/Home/About";
import Subscribe from "../Organisms/Home/Subscribe";
import Blog from "../Organisms/Home/Blog";
import Categories from "../Organisms/Home/Categories";
import Header from "../Organisms/Home/Header";

export default function HomeTemplate({ homePageData }) {

  return (
    <>
      {/* <Box sx={{ background: 'linear-gradient(180deg, #C8E8FF 13.04%, rgba(106, 170, 229, 0.83) 100%);' }} > */}
        <Header header={homePageData?.header_content} />
        <Categories categories={homePageData?.categories} />

        <About aboutData={homePageData?.about} />
        <Subscribe />
        <Blog blogs={homePageData?.blogs} mainBlog={homePageData?.main_blog} />
      {/* </Box> */}
    </>
  );
}