import React from "react";
import OurRecipes from "../Organisms/OurRecipes/OurRecipes";

export default function OurRecipesTemplate({ recipesPageData }) {
  return (
    <>
      <OurRecipes
        recipesPageData={recipesPageData}
        justifyContent={{ xs: "center" }}
      />
    </>
  );
}