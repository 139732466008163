import Types from "./Types";

export const iceCreamePicModel = () => ({
  type: Types.ICE_CREAME_PIC,
});

export const valuesCardModel = () => ({
  type: Types.VALUES_CARD,
})

export const galleryModel = (payload) => ({
  type: Types.GALLERY_IMG,
  payload
})