import React from "react";
import { Box, Stack } from "@mui/material";
import milkLoop from "../../Assets/Videos/milkLoop.webm";
import ourProduct from "../../Assets/Videos/ourProduct.mp4";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function ProductsHeader() {
  const { mainLightColor } = useThemePalette();
  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        // position="relative"
        mt={{ lg: "-145.41px", md: "-222.41px" }}
      >
        <Box
          component={"video"}
          width="100%"
          autoPlay
          loop
          sx={{ mt: { md: "25px", xs: "-36px" } }}
        >
          <source src={ourProduct} />
        </Box>
      </Stack>
    </>
  );
}
