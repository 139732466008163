import { Box } from "@mui/material";
import React, { useState } from "react";
import CardComponent from "../../Molecules/CardComponent";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function BlogCardComponent({ data, i }) {
    const [isInView, setIsInView] = useState(false);
    const navigate = useNavigate()
    const { lightCyan2 } = useThemePalette()

    return <>
        <Box key={data?.id} sx={{ width: '100%' }} >
            <motion.div
                whileInView={() => {
                    setIsInView(true);
                    return {};
                }}
                className="App"
            >
                <motion.div
                    className="box"
                    style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', cursor: 'pointer' }}
                    onClick={_ => navigate(`/blog-details/${data?.id}`)}
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{
                        // delay: i / 10 + 0.5,
                        delay: i / 6 + 0.1,
                        duration: 0.5,
                    }}
                >
                    <CardComponent
                        key={i}
                        textAlign="start"
                        padding="11px 36px 11px 20px"
                        alignItems="start"
                        width="100%"
                        // cardImg={require(`../../Assets/Images/${data.imgSrc}.png`)}
                        avatar
                        cardBgColor={lightCyan2}
                        CardComponentData={data}
                        titleFontFamily='Inter'
                        titleFontWeight='700'
                    // path={`/blog-details/${data?.id}`}
                    />
                </motion.div>
            </motion.div>
        </Box>
    </>
}
