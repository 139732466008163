import { Box } from "@mui/system";
import React from "react";
import Fustekalogo3 from "../Assets/Images/fustukaLogo.png";

export default function FustekaLogo({ xsDispaly, mdDisplay }) {
  return (
    <>
      <Box
        component="a"
        href="/"
        sx={{
          mr: { md: 2 },
          ml: { sx: 9, md: 2 },
          display: { xs: xsDispaly, md: mdDisplay },
          my: { xs: 1, md: 0 },
          width: { md: "76px", xs: "48px" },
          height: { md: "50px", xs: "25px" },
          transform: "scale(1.9)",
        }}
      >
        <img src={Fustekalogo3} alt="Logo" className="w-100" />
      </Box>
    </>
  );
}
