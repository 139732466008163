/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box } from "@mui/system";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { Stack, Container } from "@mui/material";
import SectionTitle from "../../Atoms/SectionTitle";
import CustomVideo from "../../Atoms/CustomVideo";

export default function AboutUsFactory({
  aboutPageData,
  isPlayed,
  setIsPlayed,
  playVideo,
}) {
  const [
    {
      aboutUs: { description },
    },
    setAboutUs,
  ] = useState(require("../../db/about-us.json"));
  const { mainLightColor } = useThemePalette();

  return (
    <>
      <Stack
        sx={{ width: { md: "100%", xs: "100%" } }}
        pb={{ sm: "3rem", xs: "2rem" }}
        pt={{ xl: "0", sm: "3rem", xs: "1rem" }}
        mb={{ md: "5rem", xs: "2rem" }}
      >
        <Stack sx={{ width: "100%" }}>
          <SectionTitle
            textAlign={"center"}
            lineHeight={{ md: "78.38px", xs: "normal" }}
            // width={descriptionWidth ? descriptionWidth : "658px"}
            fontSize={{ lg: "50px", md: "40px", xs: "20px" }}
            boxShadow={"none"}
            mb="30px"
            sx={{ letterSpacing: "4px", fontWeight: { md: 400, xs: 700 } }}
          >
            FACTORY
          </SectionTitle>
          {aboutPageData?.factory_video && (
            <Stack justifyContent="center" alignItems="center">
              <Box width={"100%"}>
                <CustomVideo videoUrl={aboutPageData?.factory_video} />
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
