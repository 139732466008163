import { Typography } from '@mui/material'
import React from 'react'
import { useThemePalette } from '../Theme/useThemePalatte'

export default function DescriptionComponent({ descriptionWidth, descriptionPt, descriptionFontSize = '20px', description, textAlign }) {
    const { transaprentBlack } = useThemePalette()

    return <>
        <Typography
            width={descriptionWidth ? descriptionWidth : '100%'}
            color={transaprentBlack}
            fontSize={descriptionFontSize ? descriptionFontSize : '24px'}
            fontWeight='500'
            pt={descriptionPt ? descriptionPt : '2.25rem'}
            pb={{ md: '2.25rem' }}
            textAlign={textAlign ? textAlign : 'center'}
            mb='15px'
        >
            {description}
        </Typography>
    </>
}
