import { FormHelperText, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

export default function FormInput({ inputData, formik }) {
    return <>
        {inputData.map((data, i) =>
            <Stack key={i} sx={{ width: { lg: '48%', xs: '100%' } }}>
                <TextField
                    onChange={formik.handleChange}
                    sx={{ bgcolor: 'white', borderRadius: '8px' }}
                    type={data.type}
                    name={data.name}
                    id={data.id}
                    placeholder={data.placeholder}
                />
                {data.touched &&
                    Boolean(data.error) && (
                        <FormHelperText
                            margin="0"
                            sx={{
                                color: "#ef5350",
                                alignItems: "start",
                                marginTop: '5px',
                                marginLeft: '5px'
                            }}
                        >
                            {data.errorMessage}
                        </FormHelperText>
                    )}
            </Stack>
        )}
    </>
}
