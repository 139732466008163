import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#DF6EAB",
    },
    secondary: {
      main: "#FFBF43",
    },
    info: {
      main: "#FFFFFF",
    },
    error: {
      main: "#ef5350",
    },
  },
  theme_palette: {
    mainBg: 'linear-gradient(180deg, #C8E8FF 0%, rgba(106, 170, 229, 0.83) 100%)',
    menuColor: '#284481',
    white: "#fff",
    black: "#000",
    red: '#D5242F',
    gray: "#828282",
    yellowOne: "#FFBF43",
    mainLightColor: "#E1F9FF",
    mainDarkColor: "#3C59A3",
    greenText: "#50A0B2",
    grayText: "#6D6D6D",
    darkGrayText: "#757575",
    blackTwo: "#303030",
    blue: "#342195",
    lightCyan: "#77C5FF",
    cyan: "#3093DD",
    lightOrange: "#FFC95F",
    orange: "#B88A31",
    lightPink: "#DF2589",
    pink: "#9E1C62",
    lightGreen: "#24DFB2",
    green: "rgba(0, 0, 0, 0.2)",
    transaprentBlack: "rgba(0, 0, 0, 0.6)",
    lightPurple: "#62A2D1",
    purple: "#5892BC",
    whiteTwo: "#D6D6D6",
    whiteThree: "#E1E1E1",
    babyBlue: "#62A2D1",
    babyBlueThree: "#50A0B2",
    grayTwo: "#6D6D6D",
    blueTwo: "#3C59A3",
    blueThree: "#264C9E",
    grayThree: "#757575",
    grayFour: "#515151",
    mainDarkColorTwo: "#CEEBF5",
    grayFive: "#727272",
    lightCyan2: "#CEEBF5",
    gray8: '#4F5859',
    gray10: '#696969'
  },
  typography: {
    fontFamily: ["Inter"].join(","),
    h2: {
      fontFamily: "Kool Baby",
      fontWeight: 400,
    },
    button: {
      fontFamily: "Kool Baby",
    },
    allVariants: {
      color: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1445,
      xxl: 1800,
      xxxl: 2600,
    },
  },
  // components: {
  //   MuiContainer: {
  //     styleOverrides: {
  //       maxWidthMd: {
  //         maxWidth: 320,
  //       },
  //       maxWidthLg: {
  //         maxWidth: 500,
  //       },
  //     },
  //   },
  // },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '90% !important'
        },
        maxWidthLg: {
          maxWidth: '98% !important',
        },
      }
    }
  }
});
