import React, { useEffect } from 'react'
import ContactUsTemplate from '../Templates/ContactUsTemplate'
import { useDispatch, useSelector } from 'react-redux';
import { contactUsDataRequest } from '../services/modules/contactUs';
import Loading from '../Atoms/SharedComponent/Loading';

export default function ContactUsPage() {
    const dispatch = useDispatch();

    const { contactUsPageData , isLoading } = useSelector(state => state.contactUs)
    console.log(isLoading , 'isLoading');

    useEffect(() => {
        const requestData = {
            body: { "jsonrpc": "2.0", "params": {} }
        };
        dispatch(contactUsDataRequest({ ...requestData }));
    }, []);
    return isLoading?.loading ? <Loading /> : <>
        <ContactUsTemplate contactUsPageData={contactUsPageData} />
    </>
}
