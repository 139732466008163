import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import AvatarData from "../../Molecules/AvatarData";
import AvatarImg from "../../Assets/Images/AvatarImg.svg";
import mainBlog from "../../Assets/Images/mainBlog.png";
import CustomButton from "../../Atoms/CustomButton";
import { useThemePalette } from "../../Theme/useThemePalatte";

const MainBlog = ({ data, blogPageData }) => {
  const { grayThree, blueThree } = useThemePalette();
  console.log(data?.author_image, "author_name");
  return (
    <Stack
      alignItems={{ md: "start", xs: "center" }}
      justifyContent={{ md: "start", xs: "center" }}
      flexDirection={{ sm: "column", md: "row" }}
      gap={3}
      height="100%"
    >
      <Box width={{ sm: "80%", md: "50%" }}>
        {/* <img alt="" src={require(`../../Assets/Images/${data.mainBlogImg}.png`)} width="100%" height="100%" /> */}
        <Box component="picture">
          {data?.image?.map((img) => (
            <source
              key={img?.size}
              media={`(min-width:${img?.size}px)`}
              srcSet={img?.image_url}
            />
          ))}
        </Box>
        <Box
          component="img"
          width={"100%"}
          src={data?.image?.filter((img) => img?.size == 1920)[0]?.image_url}
        />
      </Box>

      <Stack
        alignItems={{ md: "start", xs: "center" }}
        width={{ xs: "80%", md: "40%" }}
        spacing={2}
      >
        <Typography
          color={grayThree}
          fontSize={{ lg: "20px", md: "16px", sm: "15px", xs: "12px" }}
          fontWeight="600"
        >
          {data?.date}
        </Typography>
        <Typography
          fontSize={{ lg: "35px", md: "30px", sm: "20px", xs: "15px" }}
          fontWeight="400"
          variant="h2"
          letterSpacing="1px"
          color={blueThree}
          textTransform="capitalize"
          textAlign={{ md: "start", xs: "center" }}
        >
          {data?.title}
        </Typography>
        {/* <AvatarData
          avatarImg={data?.author_image}
          nameColor={grayThree}
          name={data?.author_name}
          avatarWidth="32px"
          avatarHeight="32px"
          fontSize={{ lg: "20px", md: "18px", xs: "16px" }}
        /> */}
        <Typography
          fontSize={{ lg: "22px", md: "18px", xs: "17px" }}
          color={grayThree}
          fontWeight="600"
        >
          {data?.description}
        </Typography>
        <Box>
          <CustomButton
            xsMt="12px"
            title="SHOW MORE"
            path={`/blog-details/${data?.id}`}
            fontSize="16px"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default MainBlog;
