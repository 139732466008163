import React, { useState } from 'react'
import { Stack, Button } from "@mui/material";
import subCategoryDarkBg from '../../../Assets/Images/subCategoryDarkBg.svg'

export default function SubCategory({ SubCategories, ReciveIndex, setCardActive }) {
  const [isActive, setIsActive] = useState(0)

  const handleClick = (index) => {
    setIsActive(index)
    ReciveIndex(index)
  }
  // console.log(SubCategories);
  return <>
    <Stack
      direction='row'
      width={{ md: SubCategories?.length == 2 ? '30%' : SubCategories?.length == 3 ? '45%' : SubCategories?.length == 1 ? '10%' : '60%' }}
      sx={{
        pt: { md: 4 },
        pb: { md: -4 },
        justifyContent: "space-between",
        // display: SubCategories[0]?.type ? 'flex' : 'none',
      }}
    >
      {SubCategories?.map((sub, index) =>
        sub?.type?.length > 0 && <Button
          key={index}
          sx={{
            color: isActive == index ? 'white' : '#3C59A3',
            px: { md: 3, xs: 1 },
            backgroundRepeat: 'no-repeat',
            backgroundImage: isActive == index && sub?.type && `url(${subCategoryDarkBg})`,
            fontSize: { md: '20px', xs: '12px' },
            Opacity: isActive == index && sub?.type == 'catering' && 0.1,
            backgroundSize: { md: 'cover', xs: '155% 100%' }
          }}
          onClick={() => {
            handleClick(index)
            setCardActive(1)
          }}
        >
          {sub.type}
        </Button>

      )}
    </Stack >
  </>
}
