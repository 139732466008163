import { Stack, Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  MTLModel,
  Tick,
  GLTFModel,
  DirectionLight,
  AmbientLight,
} from "react-3d-viewer";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { iceCreamePicModel } from "../../services/modules/models/Actions";
import { useDispatch } from "react-redux";
import IceCreamModel from "../../Organisms/Products/IceCreamModel";
import Motion from "../../Atoms/SharedComponent/Motion";
import { fadeInRight } from "../Home/MotionDirections";
import Loading from "../../Atoms/SharedComponent/Loading";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Canvas, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";

import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
// import { GLTFLoader } from "three/addons/loaders/GLTFLoader";

export default function ProductDetailDescription({
  finalProduct,
  productDetailsPageData,
}) {
  const [rotation, setRotaion] = useState({ x: 0, y: -100, z: 0 });
  const { blueThree, grayThree } = useThemePalette();
  const dispatch = useDispatch();
  const [isRotate, setIsRotate] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  // const gltf = useLoader(GLTFLoader, "/avatars/titled.glb");
  const [loading, setLoading] = useState(false);
  const modelRef = useRef();
  // const { scene } = useGLTF("/avatars/titled.glb");
  const handelRotate = () => {
    Tick(() => {
      let newData = rotation;
      newData.y += 0.005;
      setRotaion(newData);
      setIsRotate(false);
    });
  };

  const handleVideoEnded = () => {
    setLoading(true);
    setTimeout(() => {
      setVideoEnded(true);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      if (isRotate) handelRotate();
    }, 10);
    if (!isRotate) {
      clearTimeout(time);
      setRotaion({ x: 0, y: -100, z: 0 });
    }
  }, [isRotate]);
  console.log(productDetailsPageData);

  const iceCreamImage = productDetailsPageData?.image_url?.filter(
    (img) => img?.size == 1920
  )[0]?.image_url;

  const modelImage = productDetailsPageData?.model_url;

  return (
    <Stack
      direction={{ md: "row", xs: "column" }}
      key={productDetailsPageData?.id}
      justifyContent="space-between"
      gap="30px"
      sx={{
        pb: 3,
        pt: "1rem",
        // alignItems: { md: "center", xs: "center" },
        width: "100%",
      }}
    >
      <Box
        textAlign="center"
        sx={{ width: { md: "30%" }, position: { md: "relative" } }}
      >
        {/* ---------------- This will display the video if there is video exist else it will display uncovered image ----------------- */}
        {/* <Box>
          {productDetailsPageData?.video_url ? (
            !videoEnded &&
            !loading && (
              <Stack
                sx={{ width: "100%", position: "absolute", top: 0 }}
                justifyContent="center"
                className="dobj"
                alignItems="center"
              >
                <Box
                  sx={{
                    transform: "rotate(360deg)",
                    position: "absolute",
                    top: "0px",
                  }}
                >
                  <video
                    onEnded={(e) => handleVideoEnded()}
                    muted
                    width="100%"
                    autoPlay
                  >
                    <source src={productDetailsPageData?.video_url}></source>
                  </video>
                </Box>
              </Stack>
            )
          ) : (
            <Stack
              sx={{ width: "100%", position: "absolute", top: 0 }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  transform: "rotate(360deg)",
                  position: "absolute",
                  top: "0px",
                }}
              >
                <img
                  src={
                    productDetailsPageData?.animated_image ||
                    productDetailsPageData?.image_url?.filter(
                      (img) => img?.size == 1920
                    )[0]?.image_url
                  }
                  alt="ice cream"
                />
              </Box>
            </Stack>
          )}
        </Box> */}

        {loading && (
          <Stack
            sx={{
              width: "100%",
              height: "500px",
              position: { md: "absolute" },
              top: 0,
              zIndex: 999,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Loading />
          </Stack>
        )}
        <Box
          height={"100%"}
          width={"100%"}
          // sx={{ opacity: videoEnded && !loading ? 1 : 0 }}
        >
          {/* <Canvas
            pixelRatio={window.devicePixelRatio}
            camera={{ position: [0, 5, 10], zoom: 5, near: 1, far: 3000 }}
          >
            <ambientLight />
            <pointLight position={[100, 100, 100]} />
            <primitive object={scene} ref={modelRef} />
            <OrbitControls />
            <Environment files={"/avatars/env.hdr"} />
          </Canvas> */}
          {/* {productDetailsPageData?.model_url && (
            <img
              component="img"
              src={productDetailsPageData?.model_url}
              alt="3d img"
              style={{ width: "100%", height: "100%" }}
            />
          )} */}
          <CustomSlider
            infinite={true}
            slideToShowMd={1}
            slidesToShowXl={1}
            xsInfinite={true}
            isHoverd={false}
            arrows={false}
            length={2}
            setIsActive={(index) => console.log("Active slide:", index)}
            setIsCurrent={(index) => console.log("Current slide:", index)}
            className={"product-details-head"}
          >
            {productDetailsPageData?.image_ids?.map((img, index) => {
              return (
                <Stack
                  width={{ md: "30%" }}
                  sx={{
                    display: "flex !important",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { md: "30%", sm: "40%" },
                  }}
                >
                  <img
                    key={index}
                    src={img?.image}
                    alt="ice cream"
                    style={{maxWidth:'100%' , maxHeight:'100%'}}
                  />
                </Stack>
              );
            })}
            {/* <Box
                  sx={{
                    transform:
                      productDetailsPageData?.type == "cone"
                        ? "translateY(50%) rotate(8deg)"
                        : "",
                    textAlign: { md: "start", xs: "center" },
                  }}
                >
                  <img
                    key="ice-cream"
                    onClick={() => dispatch(iceCreamePicModel())}
                    src={iceCreamImage}
                    alt="ice cream"
                    style={{ cursor: "pointer" }}
                  />
                </Box> */}

            {/* {modelImage && (
              <img
                key="3d-model"
                src={modelImage}
                alt="3D Model"
                style={{ width: "100%", height: "100%" }}
              />
            )} */}
          </CustomSlider>
        </Box>
      </Box>
      <Stack sx={{ width: { md: "70%", xs: "100%" } }}>
        <Motion {...fadeInRight({ width: "100%", duration: "1.9" })}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: { md: "100%", xs: "100%" } }}
          >
            <Box width={{ md: "100%" }}>
              <Box sx={{ textAlign: { md: "start", xs: "center" } }}>
                <Box
                  component="img"
                  src={productDetailsPageData?.logo}
                  sx={{
                    margin: { sm: 0, xs: "auto !important" },
                    maxHeight: "152px",
                  }}
                  alt="logo"
                />
              </Box>
              <Typography
                sx={{
                  color: blueThree,
                  fontFamily: "Kool Baby",
                  fontSize: { lg: "40px", md: "30px", xs: "20px" },
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                {productDetailsPageData?.product_flavors}
              </Typography>
              {productDetailsPageData?.description && (
                <Typography
                  sx={{
                    color: grayThree,
                    fontFamily: "Inter",
                    width: "100%",
                    // lineHeight: { md: "39px", xs: ".85rem" },
                    fontSize: { lg: "20px", md: "15px", xs: "12px" },
                    fontWeight: 500,
                    mb: "20px",
                    letterSpacing: "0.2px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      productDetailsPageData?.description &&
                      productDetailsPageData?.description,
                  }}
                />
              )}
            </Box>
            {/* <Stack
              width={{ md: "30%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  transform:
                    productDetailsPageData?.type == "cone"
                      ? "translateY(50%) rotate(8deg)"
                      : "",
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                <img
                  onClick={(_) => dispatch(iceCreamePicModel())}
                  src={
                    productDetailsPageData?.image_url?.filter(
                      (img) => img?.size == 1920
                    )[0]?.image_url
                  }
                  alt="ice cream"
                  style={{ cursor: "pointer" }}
                  width={"100%"}
                />
              </Box>
            </Stack> */}
          </Stack>
        </Motion>
      </Stack>
      <IceCreamModel
        type={finalProduct?.type}
        img={
          productDetailsPageData?.image_url?.filter(
            (img) => img?.size == 1920
          )[0]?.image_url
        }
        title={productDetailsPageData?.product_name}
        productDetails={productDetailsPageData?.product_flavors}
      />
    </Stack>
  );
}
