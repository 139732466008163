import React, { useState } from "react";
import { motion } from "framer-motion";

export default function Motion({
    width,
    position,
    inialPosition,
    duration,
    delay,
    children,
    style,
    trigger,
    ...rest
}) {
    const [isInView, setIsInView] = useState(false);
    return (
        <>
            <motion.div
                whileInView={() => {
                    setIsInView(true);
                    return {};
                }}
                className="App"
                style={{ ...style, width: width }}
            >
                <motion.div
                    initial={{ width: "100%", ...inialPosition }}
                    animate={
                        isInView && {
                            ...position,
                            transition: {
                                duration,
                                origin: 1,
                                delay,
                            },
                        }
                    }
                >
                    {children}
                </motion.div>
            </motion.div>
        </>
    );
}
