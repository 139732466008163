import Types from "./Types";

export const ProductDataSuccess = (payload) => ({
  type: Types.PRODUCT_PAGE_DATA_SUCCESS,
  payload,
});

export const ProductDataLoading = (payload) => ({
  type: Types.PRODUCT_PAGE_DATA_LOADING,
  payload,
});

export const ProductDetailsDataSuccess = (payload) => ({
  type: Types.PRODUCT_DETAILS_PAGE_DATA_SUCCESS,
  payload,
});
