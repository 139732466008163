import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Motion from "../Atoms/SharedComponent/Motion";
import PagesContainer from "../Molecules/PagesContainer";
import PagesSkeleton from "../Molecules/PagesSkeleton";
import MainBlog from "../Organisms/Blog/MainBlog";
import { fadeInLeft } from "../Organisms/Home/MotionDirections";
import CustomButton from "../Atoms/CustomButton";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import BlogCardComponent from "../Atoms/SharedComponent/BlogCardComponent";

const BlogTemplate = ({ blog, mainBlog, CardComponentData, blogPageData }) => {
  const [blogsToShow, setBlogsToShow] = useState(6);
  return (
    <Stack width="100%" >
      <PagesSkeleton title="BLOGS" />
      <PagesContainer>
        <Container maxWidth="xl" >
          <Stack
            sx={{ mb: '5%' }}
            flexDirection={{ sm: "column", md: "row" }}
            flexWrap='wrap'
            direction='row'
            alignItems='center'
            rowGap='40px'
            mx='16px'
            justifyContent={blogPageData?.blogs?.length < 3 ? 'center' : 'space-between'}
          >
            {/* <Typography
              textAlign="center"
              fontSize={{ lg:'30px', md: '20px', xs: '13px' }}
              fontWeight="600"
              color="rgba(0, 0, 0, 0.6)"
            // width="840px"
            >
              {blog.description}
            </Typography> */}
            <Motion {...fadeInLeft({ width: "100%", duration: "1.9" })}>
              <MainBlog data={blogPageData?.main_blog} />
            </Motion>

            {blogPageData?.blogs?.map((data, i) =>
              <Stack sx={{ width: { sm: "48%", md: "32.4%", xs: '100%' } }}>
                <BlogCardComponent data={data} i={i} />
              </Stack>
            ).slice(0, blogsToShow)}
          </Stack>
          <Box sx={{ mt: '80px', textAlign: 'center' }} >
            {blogsToShow < blogPageData?.blogs?.length &&
              <Box onClick={(_) => setBlogsToShow(blogsToShow + 5)}>
                <CustomButton
                  title="SHOW MORE" fontSize="16px" />
              </Box>
            }
          </Box>
        </Container >
      </PagesContainer >
    </Stack >
  );
};

export default BlogTemplate;
