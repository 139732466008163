import React from "react";
import { useFormik } from "formik";
import { Box, FormHelperText, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useThemePalette } from "../../Theme/useThemePalatte";
import CustomButton from "../../Atoms/CustomButton";
import SelectBox from "../../Atoms/SelectBox";
import { validationSchema } from "./validation.js";
import FormInput from "../../Atoms/FormInput";

export default function ContactUsForm({ dropDownItems }) {
  const { mainDarkColorTwo } = useThemePalette();

  const initialValues = {
    name: "",
    email: "",
    number: "",
    message: "",
    dropDown: "",
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const inputData = [
    {
      name: "name",
      type: "text",
      error: formik.errors.name,
      touched: formik.touched.name,
      placeholder: "Enter your name",
      id: "name",
      errorMessage: "Please enter your name",
    },
    {
      name: "email",
      type: "email",
      error: formik.errors.email,
      touched: formik.touched.email,
      placeholder: "Enter your email",
      id: "email",
      errorMessage: "Please enter your email address",
    },
    {
      name: "number",
      type: "number",
      error: formik.errors.number,
      touched: formik.touched.number,
      placeholder: "Enter your number",
      id: "number",
      errorMessage: "Please enter valid number",
    },
  ];
  return (
    <>
      <Stack width={'100%'} justifyContent={'center'} alignItems='center'>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            bgcolor={mainDarkColorTwo}
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "16px" }}
            p={{ md: "40px", xs: '32px 16px' }}
            rowGap="28px"
            columnGap="24px"
            flexWrap="wrap"
          >
            <FormInput inputData={inputData} formik={formik} />

            <Stack sx={{ width: { lg: "48%", xs: "100% " } }}>
              <SelectBox
                id="dropDown"
                name="dropDown"
                items={dropDownItems}
                title="Select country"
                value={formik.values.dropDown}
                error={
                  formik.touched.dropDown && Boolean(formik.errors.dropDown)
                }
                helperText={formik.touched.dropDown && formik.errors.dropDown}
                onchange={formik.handleChange}
              />
            </Stack>

            <Stack sx={{ width: "100%" }}>
              <TextField
                onChange={formik.handleChange}
                multiline
                rows={7}
                sx={{ bgcolor: " white", width: "100%", borderRadius: "8px" }}
                type="text"
                name="message"
                id="message"
                placeholder="Enter your message"
              />
              {formik.touched.message && Boolean(formik.errors.message) && (
                <FormHelperText
                  margin="0"
                  sx={{
                    color: "#ef5350",
                    alignItems: "start",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  Required
                </FormHelperText>
              )}
            </Stack>
            <Box my="12px" width="100%">
              <CustomButton xsMt="0" type="submit" title="send" />
            </Box>
          </Stack>
        </form>
      </Stack>
    </>
  );
}
