import { reduxRequest } from "../utils/reduxRequest";
import { RecipesDataLoading, RecipesDataSuccess, RecipesDetailsDataSuccess } from "./Actions";

export const recipePageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RecipesDataLoading,
    successFunction: RecipesDataSuccess,
    url: "/recipe",
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const recipeDetailsPageDataRequest = ({id , ...variables }) => {
  const requestData = {
    isLoading: RecipesDataLoading,
    successFunction: RecipesDetailsDataSuccess,
    url: `/recipe/${id}`,
    method: "post",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
