import React, { useState } from "react";
import { Box, Stack, Container, Typography, useScrollTrigger } from "@mui/material";
import PagesContainer from "../Molecules/PagesContainer";
import PagesSkeleton from "../Molecules/PagesSkeleton";
import { useThemePalette } from "../Theme/useThemePalatte";
import SectionTitle from "../Atoms/SectionTitle";
import CustomButton from "../Atoms/CustomButton";
import { motion } from "framer-motion";
import GalleryModel from "../Organisms/Gallery/GalleryModel";
import { galleryModel } from "../services/modules/models/Actions";
import { useDispatch } from "react-redux";

export default function GalleryTemplate({ itemData, galleryData, galleryPageData }) {
  const { mainDarkColorTwo, blueTwo } = useThemePalette()
  const [isInView, setIsInView] = useState(false);
  const trigger = useScrollTrigger();
  let sum = 0;
  const dispatch = useDispatch()
  const [blogsToShow, setBlogsToShow] = useState(12);

  return (
    <Stack >
      <PagesSkeleton title="GALLERY" />
      <PagesContainer>
        <Container maxWidth="xl" >
          {/* <Stack sx={{ mb: '5%' }}>
            <Typography
              align="center"
              sx={{
                fontSize: { lg:'30px', md: "20px", sm: '16px', xs: '13px' },
                color: "#00000099",
                fontWeight: 600,
              }}
            >
             {galleryPageData?.description}
            </Typography>
          </Stack> */}
          <Stack
            flexWrap='wrap'
            direction='row'
            justifyContent={{ xs: 'space-between' }}
            // columnGap='17px'
            alignItems='center'
            rowGap='40px'
            mx='16px'
          >
            {galleryPageData?.gallery?.map((gallery, i) =>
              <Box key={gallery?.id} sx={{ width: { lg: "32.4% !important", sm: '46% !important', xs: "100%", } }} >
                <motion.div
                  whileInView={() => {
                    setIsInView(true);
                    return {};
                  }}
                  className="App"
                >
                  <motion.div
                    className="box"
                    style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{
                      delay: sum += 0.1,
                      // transition: '0.3s'
                    }}
                  >
                    <Stack
                      width='100%'
                      className='gallery'
                      sx={{
                        bgcolor: mainDarkColorTwo,
                        borderRadius: '8px',
                      }}
                    >
                      <picture onClick={_ => dispatch(galleryModel(i))}>
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: '8px',
                            // maxHeight: '286px'
                          }}
                          src={gallery?.image?.filter((img) => img?.size == 1920)[0]?.image_url}
                          alt=""
                        />
                      </picture>
                      <SectionTitle
                        boxShadow="none"
                        title={gallery?.title}
                        color={blueTwo}
                        fontSize={{lg:'22px',md:"20px"}}
                        p={"22px"}
                        sx={{ fontFamily: 'Inter !important', fontWeight: '700', textAlign: 'center' }}
                      />
                    </Stack>
                  </motion.div>
                </motion.div>
              </Box>
            ).slice(0, blogsToShow)}
          </Stack>
        </Container>
        <Box sx={{ mt: '80px', textAlign: 'center' }} >
          {blogsToShow < galleryPageData?.gallery?.filter((element) => !element?.mainBlog)?.length &&
            <Box onClick={(_) => setBlogsToShow(blogsToShow + 5)}>
              <CustomButton
                title="SHOW MORE" fontSize="16px" />
            </Box>
          }
        </Box>
      </PagesContainer>
      <GalleryModel galleryPageData={galleryPageData} />
    </Stack >
  );
}
