/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  recipesPageData: [],
  recipesDetailsPageData: [],
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function recipePageReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.RECIPES_PAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.RECIPES_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        recipesPageData: payload?.result?.data,
      };
    }
    case Types.RECIPES_DETAILS_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        recipesDetailsPageData: payload?.result?.data,
      };
    }
    default: {
      return state;
    }
  }
}
