import { Box } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import CardComponent from "../../Molecules/CardComponent";

export default function RecipesCard({ data, CardComponentData, i }) {
  const [isInView, setIsInView] = useState(false);
  return (
    <>
      <motion.div
        whileInView={() => {
          setIsInView(true);
          return {};
        }}
        className="App"
      >
        <motion.div
          className="box"
          style={{
            width: "96%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          initial={{ scale: 0.1, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            delay: i / 6 + 0.1,
            duration: 0.5,
            // delay: sum += 0.5,
            // transition: '12s'
            // delay: i + 1
          }}
        >
          <CardComponent
            CardComponentData={data} // item
            data={CardComponentData}
            width={{ md: "468px", xs: "100%" }}
            alignItems="center"
            textAlign="center"
          />
        </motion.div>
      </motion.div>
    </>
  );
}
