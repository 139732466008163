import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SelectBox from "../../Atoms/SelectBox";
import { useFormik } from "formik";
import CustomButton from "../../Atoms/CustomButton";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { DatePicker } from "@mui/x-date-pickers";
import uploadFile from '../../Assets/Images/uploadFile.svg'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

const JobForm = () => {
  const { purple } = useThemePalette();
  const [dateFromValue, setDateFromValue] = useState(null);
  const [dateToValue, setDateToValue] = useState(null);


  const initialValues = {
    name: "",
    email: "",
    number: "",
    message: "",
    dropDown: "",
    dateFrom: "",
    dateTo: "",
    uploadFile: ""
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    // validationSchema,
  });
  return (
    <Stack bgcolor="#CEEBF5" borderRadius="8px" py="40px" px={{ md: "24px", xs: '16px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" gap="28px" flexWrap="wrap">
          <Stack sx={{ width: "100%" }}>
            <FormLabel
              sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)", mb: "16px" }}
            >
              Name
            </FormLabel>
            <TextField
              onChange={formik.handleChange}
              sx={{
                bgcolor: " white",
                width: "100%",
                borderRadius: "8px",
                p: "1px",
              }}
              type="text"
              name="message"
              id="message"
              placeholder="Type your name"
            />
            {formik.touched.message && Boolean(formik.errors.message) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                Required
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <FormLabel
              sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)", mb: "16px" }}
            >
              Current position
            </FormLabel>
            <TextField
              onChange={formik.handleChange}
              sx={{ bgcolor: " white", width: "100%", borderRadius: "8px" }}
              type="text"
              name="message"
              id="message"
              placeholder="Type your current position"
            />
            {formik.touched.message && Boolean(formik.errors.message) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                Required
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <FormLabel
              sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)", mb: "16px" }}
            >
              Last education degree
            </FormLabel>
            <SelectBox
              id="dropDown"
              name="dropDown"
              items={[{}]}
              title="Last education degree"
              value={formik.values.dropDown}
              error={formik.touched.dropDown && Boolean(formik.errors.dropDown)}
              helperText={formik.touched.dropDown && formik.errors.dropDown}
              onchange={formik.handleChange}
            />
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <FormLabel
              sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)", mb: "16px" }}
            >
              Position apply for
            </FormLabel>

            <SelectBox
              id="dropDown"
              name="dropDown"
              items={[{}]}
              title="Choose from the list ..."
              value={formik.values.dropDown}
              error={formik.touched.dropDown && Boolean(formik.errors.dropDown)}
              helperText={formik.touched.dropDown && formik.errors.dropDown}
              onchange={formik.handleChange}
            />
          </Stack>

          <Stack
            sx={{ width: "100%" }}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <FormLabel
              sx={{
                fontSize: "18px",
                color: "rgba(0, 0, 0, 0.4)",
                mb: "16px",
                width: "100%",
              }}
            >
              Last job duration
            </FormLabel>
            <Stack width="48%">
              <DatePicker
                sx={{
                  bgcolor: '#fff',
                  borderRadius: '8px'
                }}
                value={formik?.values?.dateFrom}
                onChange={(newValue) =>
                  formik?.setValues({
                    ...formik?.values,
                    dateFrom: newValue,
                  })
                }
                components={{
                  OpenPickerIcon: CalendarTodayOutlinedIcon,
                }}
                name="from"
                id="from"
              />
            </Stack>
            <Stack width="48%">
              <DatePicker
                sx={{
                  bgcolor: '#fff',
                  borderRadius: '8px'
                }}
                value={formik?.values?.dateTo}
                onChange={(newValue) =>
                  formik?.setValues({
                    ...formik?.values,
                    dateTo: newValue,
                  })
                }
                components={{
                  OpenPickerIcon: CalendarTodayOutlinedIcon
                }}
                name="to"
                id='to'
              />
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <FormLabel
              sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)", mb: "16px" }}
            >
              Upload your C.V
            </FormLabel>
            {/* <TextField
              onChange={formik.handleChange}
              sx={{
                bgcolor: " white",
                width: "100%",
                borderRadius: "8px",
                '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                  // bgcolor: 'red'
                }
              }}
              type="file"
              name="uploadFile"
              id="uploadFile"
              placeholder="Choose file ..."

            /> */}
            <Box
              // variant={variant}
              // sx={inputStyle}
              disabled
              // placeholder={placeholder}
              value={formik?.values?.uploadFile}
              width="100%"
              height="56px"
              bgcolor={'#fff'}
              borderRadius='8px'
              position='relative'
            >
              <Typography color='rgba(0, 0, 0, 0.35)' p='16.5px 4px 15.5px 14px' >{' No file chosen.'}</Typography>

              <label htmlFor={`uploadFile`}>
                <input
                  id={`uploadFile`}
                  name={`uploadFile`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(value) =>
                    formik?.setValues({
                      ...formik?.values,
                      uploadFile: value?.target?.files,
                    })
                  }
                />
                <Button
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '10px'
                  }}
                >
                  <Box component='img' src={uploadFile} />
                </Button>
              </label>
            </Box>


            {formik.touched.uploadFile && Boolean(formik.errors.uploadFile) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                Required
              </FormHelperText>
            )}
          </Stack>

          <Stack width="100%" alignItems="center" justifyContent="center">
            <CustomButton xsMt="10px" type="submit" title="send" />
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default JobForm;
