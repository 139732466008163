import React, { useRef, useState } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../Atoms/CustomButton";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function Accordions({ jobs }) {
  const [expanded, setExpanded] = useState('panel0');
  const { mainDarkColor, darkGrayText } = useThemePalette();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileSelect = (file) => {
    // Do something with the selected file (e.g., display its name or upload it).
    setSelectedFile(file);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      onFileSelect(selectedFile);
    }
  };

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">
          <RemoveIcon sx={{ color: mainDarkColor }} />
        </div>
        <div className="collapsIconWrapper">
          <AddIcon sx={{ color: mainDarkColor }} />
        </div>
      </Box>
    );
  };

  const accordionList = [
    ...jobs?.map(job => {
      return {
        title: job?.name,
        details: <>
          {job?.responsibilities && <>
            <Typography fontSize={{ lg:'25px',  md: '18px', xs: '13px' }} color={darkGrayText} fontWeight="500">
              Responsibilities:
            </Typography>
            <Typography
              fontSize={{ lg:'20px', md: '18px', xs: '13px' }}
              color={darkGrayText}
              dangerouslySetInnerHTML={{ __html: job?.responsibilities }}
            />
          </>
          }
          {job?.benefits && <>
            <Typography fontSize={{ md: '18px', xs: '13px' }} color={darkGrayText} fontWeight="500">
              Benefits:
            </Typography>
            <Typography
              fontSize={{ md: '18px', xs: '13px' }}
              color={darkGrayText}
              dangerouslySetInnerHTML={{ __html: job?.benefits }}
            />
          </>
          }
        </>
      }
    })
  ];
  return (
    <>
      {accordionList?.map((data, index) => {
        return (
          <Accordion
            expanded={expanded == `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              background: " #fff",
              '& .MuiAccordionSummary-root': {
                borderTop: '0 !important', // or borderTop: 0
              },
            }}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography fontSize={{ lg:'25px', md: '20px', xs: '14px' }} sx={{ fontWeight: "bold", color: mainDarkColor }}>
                {data?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#CEEBF5" }}>
              <Box
                sx={{
                  color: "#757575",
                  py: 2,
                  fontSize: ".7rem",
                  fontWeight: 600,
                }}
              >
                {data?.details}
                <Stack sx={{
                  mt: 4,
                  alignItems: "center",
                  // position: 'relative' 
                }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  {/* <button >Choose File</button> */}
                  <Box onClick={handleFileSelect} component="span" variant="contained" >
                    <CustomButton xsMt='10px' type='file' title={"APPLY NOW"} />
                  </Box>
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion >
        );
      })}
    </>
  );
}
