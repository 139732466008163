import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import inovaLogo from "../../Assets/Images/inovaLogo.png";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useNavigate } from "react-router-dom";

export default function Copyright() {
  const { mainLightColor, grayText } = useThemePalette();

  return (
    <>
      <Stack
        bgcolor={mainLightColor}
        direction={{ md: "row" }}
        justifyContent="space-between"
        alignItems='center'
        paddingX="40px"
        paddingY="30px"
      >
        <Typography color={grayText} textAlign={{ md: 'start', xs: 'center' }} fontSize="14px">
          Copyright © FUSTEKA 2022, LLC. All Rights Reserved.
        </Typography>
        <Stack pr={{ md:'70px'}} direction="row" alignItems="center" gap="0">
          <Typography fontSize="14px" color={grayText}>
            Developed by Inova
          </Typography>
          <Button href="https://inovaeg.com/" target="_blank">
            <img src={inovaLogo} alt="inova-logo" width={'100%'} className="inova-logo-size" />
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
