import React from "react";
import { Box, Stack } from "@mui/system";
import { useThemePalette } from "../Theme/useThemePalatte";
import SectionTitle from "../Atoms/SectionTitle";
import clockIcon from "../Assets/Images/clockIconDark.svg";
import { Button, Typography } from "@mui/material";
import AvatarData from "./AvatarData";
import avatarImg from "../Assets/Images/avatar.svg";
import { useNavigate } from "react-router-dom";

export default function CardComponent({
  CardComponentData,
  textAlign,
  padding,
  avatar,
  alignItems,
  width,
  cardImg,
  gap,
  cardBgColor,
  children,
  titleFontFamily,
  titleFontWeight,
}) {
  const { blueTwo, grayFour, grayFive } = useThemePalette();

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
        <Stack
          alignItems={alignItems}
          width={width}
          bgcolor={
            cardBgColor ? cardBgColor : CardComponentData?.background_color
          }
          borderRadius="1rem"
          pb="34px"
          justifyContent="center"
          sx={{ overflow: "hidden" }}
        >
          <Box sx={{ width: "100% !important" }}>
            <img
              loading="lazy"
              src={CardComponentData?.image[1]?.image_url}
              alt=""
              style={{
                width: "100% !important",
                // height: "100%"
              }}
              width={"100% !important"}
            />
          </Box>
          <SectionTitle
            textAlign={textAlign}
            boxShadow="none"
            title={
              CardComponentData?.title
                ? CardComponentData?.title
                : CardComponentData?.card_title
            }
            color={blueTwo}
            fontSize={{ lg: "24px", md: "20px", xs: "18px" }}
            p={padding ? padding : { lg: "22px 0px", sm: "20px", xs: "10px" }}
            fontWeight={titleFontWeight && titleFontWeight}
            fontFamily={titleFontFamily && titleFontFamily}
          />
          {avatar ? (
            <Stack
              direction="row"
              alignItems="start"
              justifyContent="start"
              py="8px"
              p={padding}
            >
              {/* <AvatarData
                nameColor={grayFive}
                name={CardComponentData?.author_name}
                avatarImg={CardComponentData?.author_image}
                avatarWidth='32px'
                avatarHeight='32px'
                fontSize={{ lg:'20px', md: '18px', xs: '16px' }}
              /> */}
            </Stack>
          ) : (
            <>
              <Stack
                direction="row"
                alignItems="center"
                bgcolor={"rgba(0, 0, 0, 0.1)"}
                py="8px"
                px="36px"
                borderRadius="2rem"
              >
                <img
                  src={clockIcon}
                  alt="clock icon"
                  style={{ width: "22px", height: "22px" }}
                />
                <Typography
                  pl="24px"
                  color={grayFour}
                  fontSize={{ lg: "20px", md: "18px" }}
                  fontWeight="600"
                >
                  {CardComponentData?.recipe_time}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
}
