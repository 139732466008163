import React, { useEffect, useState } from "react";
import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
import Clouds from "../../Assets/Images/Clouds.png";
import Iceplate from "../../Assets/Images/hhh.PNG";
import activeSliderBg from "../../Assets/Images/activeSliderBg.svg";
import { Box } from "@mui/system";
import { Stack, useScrollTrigger } from "@mui/material";
import SectionTitle from "../../Atoms/SectionTitle";
import CustomButton from "../../Atoms/CustomButton";
import Svg from "../../Svg";
import { motion } from "framer-motion";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useNavigate } from "react-router-dom";

export default function Categories({ categories }) {
  const { greenText } = useThemePalette();
  const navigate = useNavigate();
  const trigger = useScrollTrigger();

  const [isActive, setIsActive] = useState(1);
  const [isCurrent, setIsCurrent] = useState();
  const [isInView, setIsInView] = useState(false);
  const [isHoverd, setIsHovers] = useState(false);
  return (
    <>
      <Stack
        alignItems="center"
        position="relative"
        mt={{ sm: "0", xs: "-30px" }}
        top={{ md: "0", sm: "20px", xs: "-30px" }}
        pt={{ lg: "0", md: "110px", xs: "52px" }}
        pb={{ md: "7rem", xs: "3rem" }}
        gap={"25px"}
      >
        <SectionTitle
          title="CATEGORIES"
          sx={{
            p: { md: "0 !important", sm: "30px" },
            m: { lg: "0 !important", md: "30px" },
          }}
          boxShadow={{
            sm: "4px 4px 0px rgba(0, 0, 0, 0.15)",
            xs: "2px 2px 0px rgba(0, 0, 0, 0.15)",
          }}
          fontSize={{ lg: "70px", md: "60px", sm: "40px", xs: "22px" }}
        />
        {/* -------------------------------------------- Categories slider ------------------------- */}
        <Stack
          sx={{ width: { xl: "90%", md: "100%" } }}
          alignItems="center"
          justifyContent="center"
        >
          <CustomSlider
            slidesToShowXl={3}
            setIsActive={setIsActive}
            isActive={isActive}
            length={categories?.length - 1}
            className="home-page"
            infinite={categories?.length > 3 ? true : false}
            xsInfinite={categories?.length > 1 ? true : false}
            setIsCurrent={setIsCurrent}
            isHoverd={isHoverd}
          >
            {categories?.map((data, i) => (
              <Box key={i}>
                <Stack
                  sx={{
                    width: "223px",
                    height: {
                      sm: "50px", // Small screens (600px and up)
                      md: "300px", // Medium screens (900px and up)
                    },

                    overflow: "visible",
                    position: "relative",
                    transition: "0.5s",
                    transform:
                      isCurrent == i
                        ? "scale(0.9) "
                        : isActive == i
                        ? "scale(1.7)"
                        : "scale(0.9)",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    component="img"
                    src={data?.image[0]?.image_url}
                    onMouseOver={(_) => setIsHovers(true)}
                    onMouseLeave={(_) => setIsHovers(false)}
                    alt=""
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: {
                        md: "block !important",
                        xs: "none !important",
                      },
                      cursor: "pointer",
                    }}
                    onMouseUp={(_) => {
                      navigate(`/product`);
                      localStorage.setItem("type", data?.name);
                    }}
                  />
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "242px",
                      display: { md: "none !important" },
                    }}
                    src={data?.image[0]?.image_url}
                    alt="slide img"
                  />
                  <Box>
                    <Box
                      // sx={{ cursor: "pointer" }}
                      // onClick={(_) => {
                      //   navigate(`/product`);
                      //   localStorage.setItem("type", data?.name);
                      // }}
                      position="relative"
                      py="2.5rem"
                      mt={{
                        xs: "-30px !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: {
                            lg: "flex !important",
                            xs: "none !important",
                          },
                        }}
                      >
                        <Svg color=" #FFFFFF" width="100%" />
                      </Box>
                      <Box
                        sx={{
                          display: {
                            lg: "none !important",
                            xs: "block !important",
                          },
                        }}
                      >
                        <Box
                          component="img"
                          src={activeSliderBg}
                          alt="title bg"
                          width={{
                            sm: "250px",
                            xs: "200px",
                          }}
                          // height={"100px"}
                        />
                      </Box>
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{ transform: "translate(-50%,-50%)" }}
                      >
                        <SectionTitle
                          title={data?.name}
                          fontSize={{
                            sm: "25px",
                            xs: "18px",
                          }}
                          color={greenText}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            ))}
          </CustomSlider>
        </Stack>
        <Box textAlign="center">
          <CustomButton
            mt="30px"
            title="SHOW MORE"
            xsMt="17px"
            width={{ md: "200px", sm: "100px", xs: "100%" }}
            path="/product"
            fontSize={{ md: "16px", xs: "12px" }}
          />
        </Box>
        {/* --------------------------------------- clouds and ice cream plate -------------------------- */}
        <Box
          width="100%"
          top={{
            md: "91% !important",
            sm: "92% !important",
            xs: "94% !important",
          }}
          position="absolute"
          in={!trigger}
        >
          <Stack
            direction="row"
            width="100%"
            position="relative"
            sx={{
              mt: {
                xs: "20px",
                // sm: "40px",
                // md: "50px",
                // lg: "60px",
                // xl: "70px",
              },
            }}
          >
            <Box alignSelf="start" width="100%" maxHeight="300px">
              <img alt="clouds" src={Clouds} className="w-100" />
            </Box>
            <motion.div
              whileInView={() => {
                setIsInView(true);
                return {};
              }}
              className="App"
            >
              <motion.div
                initial={{ "--rotate": "-40deg" }}
                // transition={{ duration: 1 }}
                animate={
                  isInView
                    ? trigger
                      ? {
                          "--rotate": "0deg",
                        }
                      : "--rotate"
                    : "0deg"
                }
              >
                <Box
                  style={{
                    transform: "rotate(var(--rotate))",
                    transformOrigin: "center",
                    transition: "1s",
                  }}
                  width={{
                    lg: "434.63px",
                    md: "350px",
                    sm: "250px",
                    xs: "156.969px",
                  }}
                  position="absolute"
                  right={{ md: "-75px", sm: "-56px", xs: "-20px" }}
                  bottom={{ md: "-90px !important ", xs: "-50px" }}
                >
                  <img
                    alt="ice plate"
                    src={Iceplate}
                    className="w-100 reload"
                  />
                </Box>
              </motion.div>
            </motion.div>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
