import React, { useState } from "react";
import PagesSkeleton from "../../Molecules/PagesSkeleton";
import background from "../../Assets/Images/background.png";
import PagesContainer from "../../Molecules/PagesContainer";
import { Stack, Container } from "@mui/material";
import SectionTitle from "../../Atoms/SectionTitle";
import map from "../../Assets/Images/map.png";
import { Box } from "@mui/system";
import ContactUsForm from "../../Molecules/ContactUs/Form";
import { useThemePalette } from "../../Theme/useThemePalatte";
import Motion from "../../Atoms/SharedComponent/Motion";
import { fadeInLeft, fadeInUp } from "../Home/MotionDirections";

export default function ContactUs({ contactUsPageData }) {
  const [{ dropDownItems }, setDropDownItems] = useState(
    require("../../db/contact-us.json")
  );
  const { blueThree } = useThemePalette()
  return (
    <>
      <PagesSkeleton title="CONTACT US" />
      <PagesContainer >
        <Container maxWidth="xl">
          <Stack sx={{ mb: '5%' }} alignItems="center" textAlign="center">
            <SectionTitle
              // lineHeight={{ md: "78.38px" }}
              fontSize={{ md: "40px", sm: '30px', xs: '15px' }}
              color={blueThree}
            >
              OUR LOCATION
            </SectionTitle>
            <Box fontSize="24px" width='100%' height='100%' pt={{ xs: '1rem' }} pb={{ md: "100px", xs: '30px' }}>
              {/* <img alt="map" width='98%' src={contactUsPageData?.image?.filter((img) => img?.size == 1920)[0]?.image_url} /> */}
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3438.5699410287743!2d47.788146!3d30.476617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDI4JzM1LjgiTiA0N8KwNDcnMTcuMyJF!5e0!3m2!1sen!2seg!4v1703762251804!5m2!1sen!2seg"
              width="98%"
              height="600"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title='fustuka location'
              style={{borderRadius:'8px', border:'none'}}
            ></iframe>
                    
            </Box>
            <SectionTitle
              lineHeight={{ md: "78.38px" }}
              sx={{ mb: "36px" }}
              fontSize={{ md: "40px", sm: '30px', xs: '15px' }}
              color={blueThree}
              textAlign={{ md: 'start', xs: 'center' }}
            >
              DROP US A MESSAGE
            </SectionTitle>
            <Stack
              sx={{ overflow: 'hidden' }}
              direction='row'
              justifyContent='center'
            >
              <Box sx={{ width: '98%' }} >
                <Motion
                  {...fadeInUp({ width: '100%' })}
                >
                  <Stack >
                    <ContactUsForm dropDownItems={dropDownItems} />
                  </Stack>
                </Motion>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </PagesContainer >
    </>
  );
}
