import { Stack, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "./components/ProductCards";
import { cheakProductType } from "./animation-func";
import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
import { useNavigate, useLocation } from "react-router-dom";

export default function ProductsList({
  SubCategories,
  OpenBtnIndex,
  isActive,
  setIsActive,
  setIsCurrent,
  isCurrent,
}) {
  const navigate = useNavigate();

  const [isHoverd, setIsHovers] = useState(false);

  // useEffect(() => {
  //   const type = localStorage.getItem("type");
  //   console.log("type", type);
  //   if (type) {
  //     navigate(`/product#${type}`);
  //   }
  //   return () => localStorage.removeItem("type");
  // }, [navigate]);
  const location = useLocation();
  const handleProductClick = (product) => {
    navigate(`/product-details/${product?.id}`, {
      state: { from: location.pathname },
    });
    localStorage.setItem("productType", product?.type);
    console.log("save productType to localstorage:", product?.type);
  };


  return (
    <Container maxWidth="lg">
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {SubCategories?.map((item, idx) => {
          return OpenBtnIndex === idx ? (
            <Stack
              key={idx}
              direction={{ md: "row", xs: "column" }}
              sx={{
                flexWrap: "nowrap",
                alignItems: "center",
                gap: 4,
                width: { xl: "100%", lg: "110%", xs: "100%" },
              }}
              className="products-page"
              justifyContent="center"
            >
              <CustomSlider
                slidesToShowXl={3}
                setIsActive={setIsActive}
                maxWidth="xl"
                className="our-product"
                infinite={item?.products?.length > 3}
                length={item?.products?.length - 1}
                setIsCurrent={setIsCurrent}
                isHoverd={isHoverd}
              >
                {item?.products?.map((product, index) => {
                  return (
                    <ProductCard
                      isCurrent={isCurrent}
                      length={item?.products?.length}
                      isActive={isActive === index}
                      index={index}
                      key={isActive}
                      product={product}
                      mediaStyle={
                        cheakProductType(
                          item?.main_type.toLowerCase(),
                          isActive === index && item?.products?.length >= 3,
                          item?.type
                        )?.style
                      }
                      item={item}
                      type={item?.main_type.toLowerCase()}
                      subCateogryType={item?.type}
                      unCoverecStyle={
                        cheakProductType(
                          item?.main_type.toLowerCase(),
                          isActive === index && item?.products?.length >= 3,
                          item?.type
                        )?.animation
                      }
                      setIsHovers={setIsHovers}
                      onClick={_=>handleProductClick(product)}
                    />
                  );
                })}
              </CustomSlider>
            </Stack>
          ) : null;
        })}
      </Stack>
    </Container>
  );
}
