import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import { useThemePalette } from "../Theme/useThemePalatte";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
function SelectBox({
  items,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  labelStyle,
  defaultValue,
  style,
  disabled,
  placeholder,
  onclick = () => { },
}) {
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const { black } = useThemePalette();
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      //   style={{ ...style, color: "black" }}
      error={error ? true : false}
      sx={{
        "& .MuiFormLabel-root": {
          color: "rgba(0, 0, 0, 0.35)"
        },
      }}
      className="select-conteiner"
    >
      <InputLabel sx={{ color: "#505050" }}>{title}</InputLabel>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        color="secondary"
        sx={{
          borderRadius: "8px",
          bgcolor: '#cecece !important'
        }}
        labelId="demo-simple-select-label"
        // defaultValue={defaultValue}
        placeholder={placeholder}
        id={id}
        name={name}
        // style={{ color: "primary" }}
        value={value}
        // label={title}
        onChange={onchange}
      >
        {items?.map((item) => (
          <MenuItem
            value={item.name}
            key={item.id}
          // onClick={() => onclick(item.id, item.name)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: black, marginLeft: "5px" }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectBox;
