import React, { useEffect, useState } from 'react'
import ModelBox from '../../Atoms/SharedComponent/Model';
import { useDispatch, useSelector } from 'react-redux';
import { valuesCardModel } from '../../services/modules/models/Actions';
import AboutUsSliderCard from '../../Molecules/AboutUs/AboutUsSliderCard';

export default function OurValuesModel({ aboutPageData }) {
    const dispatch = useDispatch()
    const { isValuesCardOpen } = useSelector(state => state.model)
    const [{ ourValues }, setAboutUsDetails] = useState(
        require("../../db/about-us.json")
    );
    const filterdData = aboutPageData?.values?.filter((value) => value?.id == localStorage?.getItem('cardId'))[0]
    useEffect(() => {
        !isValuesCardOpen && localStorage.removeItem('cardId')
    }, [!isValuesCardOpen])
    return <>
        <ModelBox
            openVar={isValuesCardOpen}
            top={'15px'}
            right='12px'
            iconBgColor='rgba(218, 218, 218, 0.30)'
            closeFunc={() => {
                dispatch(valuesCardModel());
                localStorage.removeItem('cardId')
            }}
            titleStyle={{
                fontSize: { lg: "35px", xs: "20px" },
                fontWeight: "800",
                mb: "15px",
                textAlign: "center",
                px: { lg: "50px", xs: "15px" },
            }}
            padding='0'
            mt='0'
            dialogWidth='453.765px'
            iconColor='white'
        >
            <AboutUsSliderCard descriptionFontSize='25px' data={filterdData} />
        </ModelBox>
    </>
}
