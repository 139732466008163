export const fadeInUp = ({ width = '30vw', delay }) => {
  return {
    width: width,
    duration: "0.9",
    inialPosition: { y: "50vw" },
    position: { y: 0 },
    delay: delay && delay
  };
};

export const fadeInRight = ({ i, width, duration, delay }) => {
  return {
    width: width ? width : "30vw",
    duration,
    delay: delay ? delay : i ? i * 0.5 : 0,
    inialPosition: { x: "70vw" },
    position: { x: 0 },
  };
};

export const fadeInLeft = ({ width, duration, delay }) => {
  return {
    width: width,
    duration,
    delay,
    inialPosition: { x: "-70vw" },
    position: { x: 0 },
  };
};
