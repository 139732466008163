import React, { useEffect } from 'react'
import OurRecipesTemplate from '../Templates/OurRecipesTemplate'
import { useDispatch, useSelector } from 'react-redux';
import { recipePageDataRequest } from '../services/modules/recipes';
import Loading from '../Atoms/SharedComponent/Loading';

export default function OurRecipesPage() {
    const dispatch = useDispatch();

    const { recipesPageData , isLoading} = useSelector(state => state?.recipePage)
    useEffect(() => {
        const requestData = {
            body: { jsonrpc: "2.0", params: {} },
        };
        dispatch(recipePageDataRequest({ ...requestData }));
    }, []);

    useEffect(() => {
        localStorage.setItem('recipeList', JSON.stringify(recipesPageData))
    }, [recipesPageData]);

    return isLoading?.loading ? <Loading /> : <>
        <OurRecipesTemplate recipesPageData={recipesPageData} />
    </>
}
