import Types from "./Types";

export const ContactDataSuccess = (payload) => ({
  type: Types.CONTACT_US_PAGE_DATA_SUCCESS,
  payload,
});

export const ContactDataLoading = (payload) => ({
  type: Types.CONTACT_US_PAGE_DATA_LOADING,
  payload,
});
