import { Box, Stack, Typography } from "@mui/material";
import * as dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isArabic } from "../../Atoms/SharedComponent/helperFun";
import { useThemePalette } from "../../Theme/useThemePalatte";
import AvatarData from "../AvatarData";

export default function BlogData({ blogData, mainBlog }) {
  const { darkGrayText, greenText, blueTwo, mainDarkColor, gray8 } =
    useThemePalette();
  const navigate = useNavigate();
  return (
    <Stack sx={{ width: "100%" }} alignItems="start" justifyContent="start">
      <Box>
        <Stack gap={{ md: "64px", xs: "7px" }} mb="0">
          <Box>
            <Box
              component="img"
              alt="main blog"
              src={mainBlog?.image && mainBlog?.image[0]?.image_url}
              sx={{
                width: { md: "100%", sm: "100%", xs: "256.483px" },
                cursor: "pointer",
              }}
              // className="w-100"
              onClick={(_) => navigate(`/blog-details/${mainBlog?.id}`)}
            />
          </Box>
          <Typography
            fontSize={{ md: "14px", xs: "12px" }}
            fontWeight="400"
            color={darkGrayText}
          >
            {dayjs(mainBlog?.date).format("DD MMM YYYY")}
          </Typography>
        </Stack>
        <Stack mt={{ md: "16px" }}>
          <Typography
            sx={{ textShadow: "3px 3px 0px rgba(255, 255, 255, .60)" }}
            fontSize={{ md: "37px", xs: "18px" }}
            fontWeight="700"
            color={mainDarkColor}
            width={{ md: "100%", xs: "60%" }}
          >
            {mainBlog?.title}
          </Typography>

          <Typography
            width={{ sm: "100%", xs: "80%" }}
            fontSize={{ md: "20px" }}
            pb={{ md: "2rem", xs: "1rem" }}
            pt={{ md: "2rem" }}
            color={gray8}
            direction={"rtl"}
            sx={{
              direction: isArabic(mainBlog?.description) ? "rtl" : "ltr",
            }}
          >
            {mainBlog?.description}
          </Typography>
        </Stack>
      </Box>
      {/* <AvatarData
        avatarImg={mainBlog?.author_image}
        name={mainBlog?.author_name}
        nameColor={blueTwo}
        avatarWidth="34px"
        avatarHeight="34px"
      /> */}
    </Stack>
  );
}
