import Types from "./Types";

export const RecipesDataSuccess = (payload) => ({
  type: Types.RECIPES_PAGE_DATA_SUCCESS,
  payload,
});

export const RecipesDataLoading = (payload) => ({
  type: Types.RECIPES_PAGE_DATA_LOADING,
  payload,
});


export const RecipesDetailsDataSuccess = (payload) => ({
  type: Types.RECIPES_DETAILS_PAGE_DATA_SUCCESS,
  payload,
});
