import React from "react";
import { Breadcrumbs, IconButton, Link, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomBreadcrumb = ({ onClick, breadcrumbs }) => {
  const navigate = useNavigate()
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <IconButton onClick={onClick} disableRipple>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: 500,
            display: "inline-block",
            color: "rgb(117, 117, 117)",
          }}
        >
          {"<"}
        </Typography>
      </IconButton>

      <Box mx={2} display="flex" alignItems="center" flexGrow={1}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          {breadcrumbs?.map((breadcrumb, index) =>
            breadcrumb?.link ? (
              <Button
                key={index}
                onClick={_=>navigate(breadcrumb?.link)}
                sx={{
                  cursor: "pointer",
                  color: "#3C59A3",
                  fontSize: "18px",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                {breadcrumb?.label}
              </Button>
            ) : (
              <Button
                key={index}
                // color="textPrimary"
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "rgb(117, 117, 117)",
                  // cursor:'auto'
                }}
              >
                {breadcrumb?.label}
              </Button>
            )
          )}
        </Breadcrumbs>
      </Box>
    </Box>
  );
};

export default CustomBreadcrumb;
