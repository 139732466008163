import { reduxRequest } from "../utils/reduxRequest";
import { ContactDataLoading, ContactDataSuccess } from "./Actions";

export const contactUsDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ContactDataLoading,
    successFunction: ContactDataSuccess,
    url: "/contact",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
