import React, { useEffect, useState } from "react";
import BlogTemplate from "../Templates/BlogTemplate";
import { useDispatch, useSelector } from "react-redux";
import { blogPageDataRequest } from "../services/modules/blog";
import Loading from "../Atoms/SharedComponent/Loading";

const BlogPage = () => {
  const [{ blog, mainBlog }, setBlogCardData] = useState(
    require("../db/blogs.json")
  );
  const [{ CardComponentData }, setCardComponentData] = useState(
    require("../db/blogs.json")
  );

  const dispatch = useDispatch();

  const { blogPageData, isLoading } = useSelector(state => state.blogPage)
  useEffect(() => {
    const requestData = {
      body: {
        "jsonrpc": "2.0", "params": {
          "limit": "0",
          "offset": "0"
        }
      }
    };
    dispatch(blogPageDataRequest({ ...requestData }));
  }, []);
  useEffect(() => {
    localStorage.setItem('blogList', JSON.stringify(blogPageData?.blogs))
    localStorage.setItem('mainBlog', JSON.stringify(blogPageData?.main_blog))
  }, [blogPageData]);

  return isLoading?.loading ? <Loading /> : (
    <BlogTemplate
      blog={blog}
      mainBlog={mainBlog}
      CardComponentData={CardComponentData}
      blogPageData={blogPageData}
    />
  );
};

export default BlogPage;
