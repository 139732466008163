import { Container } from "@mui/material";
import React from "react";
import ContactUs from "../Organisms/ContactUS/ContactUs";
import Layout from "../Organisms/Layout/Layout";

export default function ContactUsTemplate({contactUsPageData}) {
  return (
    <>
      <ContactUs contactUsPageData={contactUsPageData} />
    </>
  );
}
