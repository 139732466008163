import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AvatarData from "../AvatarData";
import Motion from "../../Atoms/SharedComponent/Motion";
import { fadeInRight } from "../../Organisms/Home/MotionDirections";
import CustomButton from "../../Atoms/CustomButton";
import Blog1 from "../../Assets/Images/BlogsPic10.png";
import avatar from "../../Assets/Images/avatar.svg";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useNavigate } from "react-router-dom";

export default function BlogCard({ blogCardData, blogs }) {
  const { mainDarkColor, greenText, blueTwo } = useThemePalette();
  const navigate = useNavigate();
  return (
    <Stack>
      {blogs?.map((data, i) => {
        return (
          <Motion {...fadeInRight({ width: "100%", i })} key={i}>
            <Stack
              gap={{ md: "24px" }}
              width="100%"
              direction={{ md: "row" }}
              paddingRight="53px"
              alignItems={{ md: "center" }}
              pb="23px"
            >
              <Stack mt="20px" width="260px" height="161px">
                {/* <img src={Blog1} alt="blog" height="100%" width="100%" /> */}
                <picture
                  onClick={(_) => navigate(`/blog-details/${data?.id}`)}
                  style={{ cursor: "pointer" }}
                  // style={{ display: "flex", height: "100%", width: "100%", alignItems: "center" }}
                >
                  {data?.image?.slice(0, 3)?.map((img) => (
                    <source
                      // style={{ width: "100%", bgcolor:'blue',height:'100%' }}
                      key={img?.size}
                      media={`(min-width:${img?.size}px)`}
                      srcSet={img?.image_url}
                    />
                  ))}
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={
                      data?.image?.filter((img) => img?.size == 1920)[0]
                        ?.image_url
                    }
                    alt=""
                  />
                </picture>
              </Stack>
              <Stack
                width={{ md: "180px", xs: "100%" }}
                gap={{ md: "15px", xs: "10px" }}
              >
                <Typography
                  fontSize={{ md: "20px", xs: "18px" }}
                  fontWeight="700"
                  pt="10px"
                  textTransform="capitalize"
                  color={mainDarkColor}
                  sx={{ textShadow: "2px 2px 0px rgba(255, 255, 255, .60)" }}
                  // width={{ md: '100%', xs: '100%' }}
                >
                  {data?.title}
                </Typography>
                {/* <AvatarData
                  avatarImg={data?.author_image}
                  name={data?.author_name}
                  nameColor={blueTwo}
                  avatarWidth="28px"
                  avatarHeight="28px"
                /> */}
              </Stack>
            </Stack>
          </Motion>
        );
      })}
      {/* <Box mb={{ md: "104px" }} mt={{ md: "40px" }}>
        <CustomButton
          title="Show More"
          defaultBg
          fontSize="16px"
          path="/blogs"
          width={{ md: "200px", xs: "128px" }}
        />
      </Box> */}
    </Stack>
  );
}
