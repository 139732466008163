import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableContainer } from '@mui/material';
import { useThemePalette } from '../../Theme/useThemePalatte';


export default function ProductTable({ tableRow, tableHead, tableRowData }) {
  const { blueThree, grayThree } = useThemePalette()

  function createData({ name, calories, fat, carbs }) {
    return { name, calories, fat, carbs };
  }
  const getStripedStyle = (index) => {
    return {
      "&:nth-of-type(even)": {
        backgroundColor: 'white'
      },
    };
  }
  return (
    <TableContainer component={Paper} sx={{ background: 'transparent', boxShadow: '0' }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow sx={{ bgcolor: 'white' }}>
            {tableHead && tableHead?.map((head) =>
            <TableCell  align="center" sx={{ bgcolor:'#9FA8EB', padding: { lg: 3, md: 1, sm: 2, xs: 1 }, border: 0, color: blueThree, fontWeight: 800, fontSize: { lg:'25px', md: '18px', sm: '16px', xs: '12px' } }}>{head}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRowData && tableRowData?.map((row, index) => (
            <TableRow
              key={row?.id}
              sx={{
                padding: '5px 20px',
                // height: 25,
                // bgcolor: '#09c',
                ...getStripedStyle(index)
              }}>
              <TableCell align="left" sx={{ padding: { lg: 3, md: 1, sm: 2, xs: '15px 6px' }, border: 0, color: grayThree, fontSize: { lg:'22px', md: '18px', sm: '15px', xs: '12px' }, fontWeight: 800 }}>  {row?.name}</TableCell>
              <TableCell align="center" sx={{ padding: { lg: 3, md: 1, sm: 2, xs: '15px 6px' }, border: 0, color: grayThree, fontSize: { lg:'22px', md: '18px', sm: '15px', xs: '12px' }, fontWeight: 600 }}>  {row?.value_per_100g} {row?.unit} </TableCell>
              <TableCell align="center" sx={{ padding: { lg: 3, md: 1, sm: 2, xs: '15px 6px' }, border: 0, color: grayThree, fontSize: { lg:'22px', md: '18px', sm: '15px', xs: '12px' }, fontWeight: 600 }}>  {row?.value_per_serving} {row?.unit}</TableCell>
              <TableCell align="center" sx={{ padding: { lg: 3, md: 1, sm: 2, xs: '15px 6px' }, border: 0, color: grayThree, fontSize: { lg:'22px', md: '18px', sm: '15px', xs: '12px' }, fontWeight: 600, '&.css-10o66ui-MuiTableCell-root': { color: 'blue' } }}> {row?.ref_quantity}</TableCell>
            </TableRow >
          ))
          }
        </TableBody >
      </Table >
    </TableContainer >
  );
}
