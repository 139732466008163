import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import nav from "../../Assets/Images/navLast.svg";
import mobileNav from "../../Assets/Images/mobilebg.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import FustekaLogo from "../../Atoms/FustekaLogo";
import PagesContainer from "../../Molecules/PagesContainer";
import { motion } from "framer-motion";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useNavigate } from "react-router-dom";
import { ListItem, ListItemText, Drawer, Stack, List } from '@mui/material';
import Fustekalogo3 from "../../Assets/Images/Fustekalogo3.png";
import homeIcon from "../../Assets/Images/icons/homeIcon.svg";
import aboutIcon from "../../Assets/Images/icons/aboutIcon.svg";
import productsIcon from "../../Assets/Images/icons/productsIcon.svg";
import recipesIcon from "../../Assets/Images/icons/recipesIcon.svg";
import galleryIcon from "../../Assets/Images/icons/galleryIcon.svg";
import blogsIcon from "../../Assets/Images/icons/blogsIcon.svg";
import careersIcon from "../../Assets/Images/icons/careersIcon.svg";
import contactIcon from "../../Assets/Images/icons/contactIcon.svg";
import homeActive from "../../Assets/Images/icons/homeActive.svg";
import aboutActive from "../../Assets/Images/icons/aboutActive.svg";
import productsActive from "../../Assets/Images/icons/productsActive.svg";
import recipesActive from "../../Assets/Images/icons/recipesActive.svg";
import galleryActive from "../../Assets/Images/icons/galleryActive.svg";
import blogsActive from "../../Assets/Images/icons/blogsActive.svg";
import careersActive from "../../Assets/Images/icons/careersActive.svg";
import contactActive from "../../Assets/Images/icons/contactActive.svg";

export default function Navbar() {
  const navigate = useNavigate()
  const [top, setTop] = useState("35px");
  const { menuColor } = useThemePalette()
  const { pathname } = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false);

  let pages = [
    { title: "Home", path: "/", icon: homeIcon, active: homeActive },
    { title: "About Us", path: "/about", icon: aboutIcon, active: aboutActive },
    { title: "Products", path: "/product", icon: productsIcon, active: productsActive },
    { title: "Recipes", path: "/recipes", icon: recipesIcon, active: recipesActive },
    { title: <FustekaLogo xsDispaly="none" mdDisplay="flex" />, path: "/", type: 'logo' },
    { title: "Gallery", path: "/gallery", icon: galleryIcon, active: galleryActive },
    { title: "Blog", path: "/blogs", icon: blogsIcon, active: blogsActive },
    { title: "Career", path: "/career", icon: careersIcon, active: careersActive },
    { title: "Contact", path: "/contact", icon: contactIcon, active: contactActive },
  ];

  window.addEventListener(
    "scroll",
    function (event) {
      var top = this.scrollY,
        left = this.scrollX;
      // console.log(top, left);
      if (0 == top < 100) setTop(0);
      else setTop("35px");
    },
    false
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenNavMenu = (e) => {
    handleDrawerToggle()
  };

  const drawerContent = (
    <List>
      {pages?.filter((e) => e?.type != 'logo')?.map((page, index) => (
        <Link key={index} to={page?.path}
          style={{
            textDecoration: 'none', color: 'inherit',
            // borderTop: '1px solid #09c',
          }}>
          <ListItem button key={index}
            onClick={_ => {
              handleDrawerToggle()
            }}
            sx={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.10) ',
              borderTop: index == 0 && '1px solid rgba(0, 0, 0, 0.10)',
              '& span': {
                color: pathname == page?.path ? '#D5242F' : '#264C9E',
                fontWeight: pathname == page?.path ? 900 : 500,
              }
            }}
          >
            <Box
              component='img'
              src={pathname == page?.path ? page?.active : page?.icon}
              minWidth='24px'
            />
            <ListItemText sx={{ pl: '1rem' }} primary={page.title} />
          </ListItem>
        </Link>
      ))
      }
    </List >
  );

  return (
    <Box position="relative" zIndex="10" width="100%" top="0" height="100%">
      <PagesContainer
        bgImg={nav}
        mobileViewImg={mobileNav}
        // backgroundSize={{ sm: "64.5% 83px", xs: "100%" }}
        backgroundPosition={'bottom'}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            // height: "70px",
          }}
        >
          <Container maxWidth="lg" disableGutters>
            <Toolbar disableGutters sx={{ justifyContent: "space-between", minHeight: '46px' }}>
              {/* --------------------------- Responsive Menu Items --------------------- */}
              <Box className="navbar-container" sx={{ display: { xs: "flex", md: "none" }, mt: '25px', width: '98%' }}>
                <motion.div
                  className="navbar-mobileBg"
                  style={{
                    top: top,
                    width: "100%",
                  }}
                  animate={{
                    y: top,
                  }}
                  transition={{ ease: "easeOut", duration: 0.8 }}
                >
                  <Stack direction='row' sx={{ width: '55%', justifyContent: 'space-between', height: '100%' }} >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      // color={menuColor}
                      sx={{ color: menuColor }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box sx={{ justifySelf: 'center', alignSelf: 'center' }} >
                      <FustekaLogo xsDispaly="flex" mdDisplay="none" />
                    </Box>
                  </Stack>
                  <div>
                    {/* <Hidden smUp implementation="css"> */}
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                    >
                      {/* <MenuIcon /> */}
                    </IconButton>
                    <Drawer
                      variant="temporary"
                      anchor="left"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      sx={{
                        // paper: classes.drawerPaper,
                        '& .MuiDrawer-paper': {
                          width: '70%',
                          bgcolor: '#C8E8FF',
                          color: '#0D0D0D'
                        }
                      }}
                      ModalProps={{
                        keepMounted: true,
                      }}
                    >
                      <Stack
                        onClick={_ => {
                          navigate('/')
                          handleDrawerToggle()
                        }}
                        alignItems='center' my='40px' >
                        {/* <FustekaLogo xsDispaly="flex" mdDisplay="none" /> */}
                        <img src={Fustekalogo3} width='106px' height='69px' alt="Logo" />
                      </Stack>
                      {drawerContent}
                    </Drawer>
                    {/* </Hidden> */}
                  </div>

                </motion.div>
              </Box>


















              {/* ------------------------- Responsive Logo ---------------------------- */}
              {/* <FustekaLogo xsDispaly="flex" mdDisplay="none" /> */}
              {/* ----------------------------- Web Pages ------------------------- */}
              <Box
                className="navbar-container"
                width={{ lg: "85%", xs: '100%' }}
                display={{
                  xs: "none",
                  md: "flex",
                  justifyContent: 'center'
                }}
                // left={{ md: "15%", xxl: "28%", xxxl: "35%" }}
                top={top}
              >
                <motion.div
                  className="navbar"
                  style={{
                    top: top,
                    width: "100%",
                  }}
                  animate={{
                    y: top,
                  }}
                  transition={{ ease: "easeOut", duration: 0.8 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingX: "35px",
                      width: "90%",
                    }}
                  >
                    {pages.map((page, i) => (
                      <NavLink
                        to={page.path}
                        key={i}
                        className={({ isActive }) =>
                          isActive && i !== 4
                            ? "active navlink-stlye"
                            : "navlink-stlye"
                        }
                      >
                        {/*       margin: "-22px 0px 0px -7px!important", */}
                        {page.title}
                      </NavLink>
                    ))}
                  </Box>
                </motion.div>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </PagesContainer>
    </Box>
  );
}
