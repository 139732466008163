import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import { cheakProductType } from "../animation-func";
import { useSelector } from "react-redux";
export default function ProductCard({
  index,
  product,
  mediaStyle,
  id,
  isActive,
  length,
  unCoverecStyle,
  type,
  isCurrent,
  setIsHovers,
  onClick,
}) {
  const { cardsColors } = useSelector(state => state?.productPage)
  const getScale = () => {
    if (length > 3) {
      if (isCurrent == index) {
        return "scale(0.7)";
      } else {
        if (isActive) {
          return "scale(1)";
        } else {
          return "scale(0.7)";
        }
      }
    }
    if (length < 3) {
      return "scale(0.9)";
    } else {
      if (isActive) return "scale(0.9)";
      else return "scale(0.7)";
    }
  };

  function darkenHexColor(hex, percentage) {
    // Remove the hash at the start if it's there
    hex = hex?.replace(/^#/, '');

    // Split the hex color into its components
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate the darker shade
    r = Math.max(0, Math.min(255, r - Math.round(r * (percentage / 100))));
    g = Math.max(0, Math.min(255, g - Math.round(g * (percentage / 100))));
    b = Math.max(0, Math.min(255, b - Math.round(b * (percentage / 100))));

    // Convert the components back to a hex string and pad with zeroes if necessary
    let darkerHex = `#${(r.toString(16).padStart(2, '0'))}${(g.toString(16).padStart(2, '0'))}${(b.toString(16).padStart(2, '0'))}`;

    return darkerHex.toUpperCase();  // Return the darker color in uppercase
  }
  return (
    <Stack
      key={index}
      justifyContent="space-between"
      onClick={() => onClick()}
      // id={product?.type?.toUpperCase()}
      width="100%"
      // height={{ xs: "100%" }}
      // direction={{ md: "row", xs: "column" }}

      sx={{
        borderRadius: "16px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "100% !important",
        // height: "100%",
        alignItems: "center",
        ////// // mx: "10px",
        pt: product?.type == "cup" && "40px",
        // "#D4F4D4" : "#FFE0DB"
        backgroundColor:
          isCurrent == index
            ? cardsColors?.inactive
            : isActive
              ? cardsColors?.active
              : cardsColors?.inactive,

        transition: "background-color 0.8s, transform 0.8s",
        transform: getScale(),
        mb: product?.type == "cup" && "40px",
        cursor: "pointer",

        // ...sliderCardStyle
      }}
      id={id}
      onMouseOver={() => setIsHovers(true)}
      onMouseLeave={() => setIsHovers(false)}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          // alignItems: "center",
          // mx: "10px",
          pb: cheakProductType(type, isActive)?.paddingBottom,
        }}
      >
        <Box>
          <Box
            component="img"
            sx={{
              ...mediaStyle,
            }}
            src={
              product?.image_url?.filter((img) => img?.size == 1024)[0]
                ?.image_url
            }
            alt="no image"
          />
        </Box>
        {product?.animated_image && (
          <Box>
            <Box
              sx={{
                ...unCoverecStyle,
              }}
              component="img"
              src={product?.animated_image}
              alt="cones image"
              loading="lazy"
            />
          </Box>
        )}
      </Stack>
      {/* 
      <Stack> */}
      <Stack alignItems="center" justifyContent="end" mb="20px">
        <img
          src={product?.logo}
          className="logo"
          // width="100%"
          alt="logo"
        />
      </Stack>
      <Typography
        align="center"
        variant="h2"
        sx={{
          // mt: "20px",
          color: "#515151",
          fontWeight: 800,
          lineHeight: product?.productName?.length > 28 ? "22px" : "43px",
          letterSpacing: "1px",
          textTransform: "capitalize",
          fontSize: "20px",
          bgcolor: isCurrent == index ?
            darkenHexColor(cardsColors?.inactive, 10) :
            isActive ? darkenHexColor(cardsColors?.active, 10) :
              darkenHexColor(cardsColors?.inactive, 10),
          transition: "background-color 0.8s, transform 0.8s",
          borderRadius: "0 0 16px 16px",
          py: "10px",
          // px: "18px",
          width: "100%",
          fontFamily: "Inter",
        }}
      >
        {product?.product_flavors}
      </Typography>
    </Stack>
    // </Stack>
  );
}
