import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SectionTitle from "../SectionTitle";
import { useThemePalette } from "../../Theme/useThemePalatte";
import clockIcon from "../../Assets/Images/clockIconDark.svg";
import blogCard3 from "../../Assets/Images/blogCard3.png";
import RecipesCard from "../SharedComponent/RecipesCard";
import CustomButton from "../CustomButton";
import BlogCardComponent from "./BlogCardComponent";
import AvatarData from "../../Molecules/AvatarData";
import * as dayjs from "dayjs";

export default function DetailsComponent({
  data,
  product,
  moreData,
  moreDataa,
  avatar,
  mainBlog,
}) {
  const { blueThree, grayFour, grayThree, red, gray10 } = useThemePalette();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(data, "data");
  return (
    <>
      <Box className="details" sx={{ pt: { md: 15, xs: 5 } }}>
        <Container maxWidth="xl">
          <Stack>
            {data?.date && (
              <Typography
                color={grayThree}
                fontSize={{ md: "16px", sm: "15px", xs: "12px" }}
                fontWeight="600"
                textAlign="center"
              >
                {dayjs(data?.date).format("DD MMM YYYY")}
              </Typography>
            )}
            <SectionTitle
              textAlign={"center"}
              lineHeight={{ md: "78.38px", xs: "50px" }}
              fontSize={{ md: "40px", sm: "20px", xs: "15px" }}
              boxShadow={0}
              color={blueThree}
            >
              {data?.card_title ? data?.card_title : data?.title}
            </SectionTitle>
            {avatar && (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <AvatarData
                  avatarImg={data?.author_image}
                  nameColor={grayThree}
                  name={data?.author_name}
                  avatarWidth="32px"
                  avatarHeight="32px"
                  fontSize={{ md: "18px", xs: "16px" }}
                />
              </Stack>
            )}

            {data?.recipe_time && (
              <Stack direction="row" justifyContent="center" pt="16px">
                <Stack
                  direction="row"
                  alignItems="center"
                  bgcolor={"rgba(0, 0, 0, 0.1)"}
                  py="8px"
                  px="36px"
                  borderRadius="2rem"
                >
                  <img
                    src={clockIcon}
                    alt="clock icon"
                    style={{ width: "22px", height: "22px" }}
                  />
                  {data?.recipe_time && (
                    <Typography
                      pl="10px"
                      color={grayFour}
                      fontSize={{ md: "18px", xs: "15px" }}
                      fontWeight="600"
                    >
                      {data?.recipe_time}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
            {moreData == "recipes"
              ? data?.video_url && (
                  <Box
                    mt="20px"
                    mb={{ md: "32px", xs: "17px" }}
                    component="video"
                    alt="recipe image"
                    src={data?.video_url}
                    sx={{ borderRadius: "15px" }}
                    autoPlay
                    loop
                  />
                )
              : data?.image && (
                  <Box
                    mt="20px"
                    mb={{ md: "32px", xs: "17px" }}
                    component="img"
                    alt="recipe image"
                    src={data?.image[0]?.image_url}
                    // src={blogCard3}
                    sx={{ borderRadius: "15px" }}
                  />
                )}
            {data?.article ? (
              <Typography
                mb="40px"
                color={grayThree}
                fontSize={{ md: "22px", xs: "17px" }}
                fontWeight="500"
                textAlign="center"
                dangerouslySetInnerHTML={{ __html: data?.article }}
              />
            ) : (
              <Typography
                mb="40px"
                color={grayThree}
                fontSize={{ lg: "30px", md: "22px", xs: "17px" }}
                fontWeight="500"
                // textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: data?.description
                    ? data?.description
                    : product?.description,
                }}
              />
            )}
            {data?.ingredients && (
              <Box>
                <Typography
                  color={grayThree}
                  fontWeight={"bold"}
                  fontSize={{ lg: "35px", md: "25px", xs: "17px" }}
                  pb="20px"
                  textTransform="capitalize"
                >
                  ingredients
                </Typography>
                <Typography
                  mb="40px"
                  color={grayThree}
                  fontSize={{ lg: "30px", md: "22px", xs: "17px" }}
                  fontWeight="500"
                  // textAlign='center'
                  dangerouslySetInnerHTML={{
                    __html: data?.ingredients,
                  }}
                />
              </Box>
            )}
            {data?.step_by_step && (
              <Box>
                <Typography
                  color={grayThree}
                  fontWeight={"bold"}
                  fontSize={{ lg: "35px", md: "25px", xs: "17px" }}
                  pb="20px"
                  textTransform="capitalize"
                >
                  step by step
                </Typography>
                <Typography
                  mb="40px"
                  color={grayThree}
                  fontSize={{ lg: "30px", md: "22px", xs: "17px" }}
                  fontWeight="500"
                  // textAlign='center'
                  dangerouslySetInnerHTML={{
                    __html: data?.step_by_step,
                  }}
                />
              </Box>
            )}
            {(moreDataa?.filter((ele) => ele?.id != id)?.length > 1 ||
              id != mainBlog?.id) && (
              <SectionTitle
                textAlign={"center"}
                fontSize={{ md: "40px", sm: "20px", xs: "15px" }}
                boxShadow={0}
                mt="40px"
                color={blueThree}
                textTransform={"capitalize"}
              >
                more {moreData}
              </SectionTitle>
            )}
            {moreData == "recipes" && (
              <Stack
                direction="row"
                justifyContent={{ lg: "center", sm: "space-between" }}
                columnGap={{ lg: "112px" }}
                rowGap={"20px"}
                flexWrap="wrap"
                mt="32px"
                sx={{ cursor: "pointer" }}
              >
                {moreDataa
                  ?.filter((ele) => ele?.id != id)
                  ?.map((data, i) => (
                    <Box
                      onClick={(_) => navigate(`/recipes-details/${data?.id}`)}
                      key={data?.id}
                      sx={{ width: { lg: "32.4%", sm: "47%", xs: "100%" } }}
                    >
                      <RecipesCard data={data} CardComponentData={data} i={i} />
                    </Box>
                  ))
                  .slice(0, 2)}
              </Stack>
            )}
            {moreData == "blogs" && (
              <Stack
                direction="row"
                justifyContent="center"
                columnGap="17px"
                rowGap="20px"
                flexWrap="wrap"
                mt="32px"
              >
                {id != mainBlog?.id && (
                  <Stack
                    key={data?.id}
                    onClick={(_) => navigate(`/blog-details/${mainBlog?.id}`)}
                    sx={{ width: { sm: "100%", md: "32.4%" } }}
                  >
                    <BlogCardComponent data={mainBlog} />
                  </Stack>
                )}
                {moreDataa
                  ?.filter((ele) => ele?.id != id)
                  .map((data, i) => (
                    <Stack
                      key={data?.id}
                      onClick={(_) => navigate(`/blog-details/${data?.id}`)}
                      sx={{ width: { sm: "100%", md: "32.4%" } }}
                    >
                      <BlogCardComponent
                        data={data}
                        CardComponentData={data}
                        i={i}
                      />
                    </Stack>
                  ))
                  .slice(0, 2)}
              </Stack>
            )}
            {moreDataa?.filter((ele) => ele?.id != id)?.length > 1 && (
              <Stack alignItems="center" mt="80px">
                <CustomButton path={`/${moreData}`} title="Show More" />
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
}
