import { Stack } from "@mui/material";
import React, { useState } from "react";
import ProductCard from "../../Molecules/products/components/ProductCards";
import { cheakProductType } from "../../Molecules/products/animation-func";
import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
import { useNavigate } from "react-router-dom";

export default function ProductDetailCards({ finalProduct }) {
  const [isActive, setIsActive] = useState(1);
  const [isInView, setIsInView] = useState(false);
  const [isCurrent, setIsCurrent] = useState(1);
  const [isHoverd, setIsHovers] = useState(false);
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        // spacing={6}
        direction={{ md: "row", xs: "column" }}
        sx={{
          // mt: '-126px',
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <CustomSlider
          slidesToShowXl={3}
          setIsActive={setIsActive}
          maxWidth="lg"
          className="our-product product-details"
          infinite={finalProduct?.length > 3 ? true : false}
          length={finalProduct?.length - 1}
          setIsCurrent={setIsCurrent}
          isHoverd={isHoverd}
        >
          {finalProduct?.map((product, index) => {
            //if the id of the opened product=id of the other products of same category return null else return the other products
            // return finalProduct.productId != product.productId ? (
            return (
                <ProductCard
                  product={product}
                  index={index}
                  isCurrent={isCurrent}
                  isActive={isActive == index}
                  key={isActive}
                  length={finalProduct?.length}
                  mediaStyle={
                    cheakProductType(
                      product?.type?.toLowerCase(),
                      isActive == index && finalProduct?.length >= 3,
                      false,
                      true
                    )?.style
                  }
                  unCoverecStyle={
                    cheakProductType(
                      product?.type?.toLowerCase(),
                      isActive == index && finalProduct?.length >= 3,
                      false,
                      true
                    )?.animation
                  }
                  type={product?.type}
                  setIsHovers={setIsHovers}
                  onClick={(_) => navigate(`/product-details/${product?.id}`)}
                />
            );
            // ) : null;
          })}
        </CustomSlider>
      </Stack>
    </Stack>
  );
}
