import { Modal, Stack, Typography, Box, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModelBox = ({
  title,
  padding = "20px",
  mt = "20px",
  children,
  closeFunc,
  openVar,
  titleStyle,
  modelBorderRadius,
  justifyContent,
  px,
  sx,
  dialogWidth,
  iconColor,
  top,
  right,
  bgcolor,
  iconBgColor,
  maxWidth,
  dialogHeight,
}) => {
  return (
    <Dialog
      open={openVar}
      onClose={closeFunc}
      className="model"
      sx={{
        "& .MuiDialog-container": {
          // height: 'auto !important'
          maxWidth:'100% !important'
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: modelBorderRadius ? modelBorderRadius : "12px",
          height: dialogHeight,
          width: dialogWidth
            ? dialogWidth
            : { lg: "1272px", md: "800px", sm: "500px", xs: "380px" },
          padding: padding,
          px: px,
          ...sx,
          maxWidth: maxWidth,
          bgcolor: bgcolor,
          
        },
      }}
      
    >
      <Stack
        onClick={closeFunc}
        position="relative"
        alignItems="flex-end"
        sx={{
          cursor: "pointer",
          mt: mt,
        }}
      >
        <Box
          position="absolute"
          sx={{
            padding: "4px",
            width: { lg: "34px", xs: "20px" },
            height: { lg: "34px", xs: "20px" },
            display: "flex",
            alignItems: "center !important",
            justifyContent: "center !important",
            top: top,
            right: right,
            zIndex: "999999",
            bgcolor: iconBgColor,
            borderRadius: "50%",
          }}
        >
          <CloseIcon sx={{ fontSize: "30px", color: iconColor }} />
        </Box>
      </Stack>
      {title && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={justifyContent ? justifyContent : "center"}
          mb={2}
          mx={1}
        >
          <Typography sx={titleStyle} component="h2">
            {title}
          </Typography>
        </Stack>
      )}
      {children}
    </Dialog>
  );
};

export default ModelBox;
