import React from "react";
import PagesContainer from "./PagesContainer";
import { Box, Grid } from "@mui/material";
import Motion from "../Atoms/SharedComponent/Motion";
import { fadeInRight, fadeInLeft } from "../Organisms/Home/MotionDirections";
import SectionTitle from "../Atoms/SectionTitle";
import { Stack } from "@mui/system";
import chocolate2 from "../Assets/Videos/chocolate2.webm";
import CustomButton from "../Atoms/CustomButton";

export default function SkeletonComponent({
  title,
  leftSide,
  RightSide,
  leftSideWidth,
  rightSideWidth,
  chocolate,
  pt,
  pb,
  alignItems,
  rightSideAlignSelf,
  leftDuration,
  rightDuration,
  boxShadow,
  fontSize = "70px",
  titlePt,
  displayShowMoreBtn,
  showMorePath,
  showMoreTitle,
}) {
  return (
    <>
      <PagesContainer
        pt={{ xl: "14rem", lg: "11rem", md: "10rem", sm: "5rem", xs: "4rem" }}
        alignItems="start"
      >
        {title && (
          <Box
            sx={{ pt: titlePt }}
            marginLeft={{ md: "40px", xs: "19px" }}
            mb={{ md: "38px", xs: "10px" }}
          >
            <SectionTitle
              boxShadow={boxShadow}
              title={title}
              fontSize={fontSize}
            />
          </Box>
        )}
        <Stack
          justifyContent="space-between"
          zIndex={0}
          overflowY="visible hidden"
          position="relative"
          alignItems={alignItems}
          pb={pb}
          pt={pt}
          sx={{ px: { lg: "0", xs: "1rem" }, width: "100%" }}
          gap={{ sm: "20px" }}
          flexDirection={{ md: "row" }}
        >
          <Box sx={{ width: leftSideWidth }}>
            <Motion {...fadeInLeft({ width: "100%", duration: leftDuration })}>
              <Stack width="100%" marginLeft={{ md: "32.5px" }}>
                {leftSide}
              </Stack>
            </Motion>
          </Box>

          <Motion
            {...fadeInRight({ width: rightSideWidth, duration: rightDuration })}
            style={{ alignSelf: rightSideAlignSelf }}
            alignSelf={rightSideAlignSelf}
          >
            <Stack width="100%">{RightSide}</Stack>
          </Motion>

          {/* {chocolate && (
            <Stack
              position="absolute"
              //           top: -87px;
              // right: -179px;
              top={{ lg: "-308px", md: '-240px', xs: '-113px' }}
              right={{ lg: "37%", md: '28%', xs: ' -140px' }}
              className="chocolate-video"
            >
              <Box
                component={'video'}
                playsInline
                sx={{ width: { lg: '100%', md: '72%', xs: '56%' } }}
                width='100%'
                muted
                autoPlay 
              >
                <source playsInline src={chocolate2} />
              </Box>
            </Stack>
          )} */}
        </Stack>
        {displayShowMoreBtn && (
          <Stack
            justifyContent="center"
            alignItems="center"
            mb={{ md: "104px" }}
            mt={{ md: "40px" }}
            sx={{ width: "100%" }}
          >
            <CustomButton
              title={showMoreTitle}
              defaultBg
              fontSize="16px"
              path={showMorePath}
              width={{ md: "200px", xs: "128px" }}
            />
          </Stack>
        )}
      </PagesContainer>
    </>
  );
}
