import { reduxRequest } from "../utils/reduxRequest";
import { CareerDataLoading, CareerDataSuccess } from "./Actions";

export const careerPageDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CareerDataLoading,
    successFunction: CareerDataSuccess,
    url: "/career",
    method: "get",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
