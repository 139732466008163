import React from "react";
import PagesContainer from "./PagesContainer";
import staticBg from "../Assets/Images/static-bg.png";
import headersBg from "../Assets/Images/headersBg.svg";
import SectionTitle from "../Atoms/SectionTitle";
import { Box } from "@mui/material";
import milkLoop from '../Assets/Videos/milkLoop.webm'

export default function PagesSkeleton({ title }) {
  return (
    <Box height="100%" width="100%">
      <PagesContainer
        bgImg={staticBg}
        mobileViewImg={headersBg}
        marginTop="-46px"
        backgroundSize="100%"
      >
        <SectionTitle
          title={title}
          sx={{ textShadow: "5px 5px 0px #ece8e8" }}
          py={{ md: "12rem", sm: '8rem', xs: '6rem' }}
          fontSize={{ md: "100px", sm: '60px', xs: '30px' }}
        />
      </PagesContainer>
      <Box height="100%" mt={'-12%'} width="100%">
        <video muted width="100%" autoPlay loop>
          <source src={milkLoop} />
        </video>
      </Box>
    </Box>
  );
}
