import React, { useEffect, useState } from "react";
import GalleryTemplate from "../Templates/GalleryTemplate";
import { useDispatch, useSelector } from "react-redux";
import { galleryPageDataRequest } from "../services/modules/gallery";
import Loading from "../Atoms/SharedComponent/Loading";

export default function GalleryPage() {
  const itemData = [
    {
      img: "./assets/Rectangle 14.png",
      title: "Pic1",
      rows: 4,
      cols: 2,
    },
    {
      img: "./assets/Rectangle 15.png",
      author: "@arwinneil",
      rows: 2,
      cols: 2,
    },

    {
      img: "./assets/Rectangle 19.png",
      title: "pic3",
      cols: 2,
      rows: 1,
    },
    {
      img: "./assets/Rectangle 18.png",
      rows: 3,
      cols: 2,
    },

    {
      img: "./assets/Rectangle 18.png",
      rows: 2,
      cols: 1,
    },
    {
      img: "./assets/Rectangle 17.png",
      rows: 2,
      cols: 1,
    },
    {
      img: "./assets/Rectangle 15.png",
      author: "@arwinneil",
      rows: 2,
      cols: 2,
    },
    {
      img: "./assets/Rectangle 14.png",
      title: "Pic1",
      rows: 4,
      cols: 2,
    },
    {
      img: "./assets/Rectangle 19.png",
      title: "pic3",
      cols: 2,
      rows: 1,
    },
    {
      img: "./assets/Rectangle 18.png",
      rows: 3,
      cols: 2,
    },
    {
      img: "./assets/Rectangle 18.png",
      rows: 2,
      cols: 1,
    },
    {
      img: "./assets/Rectangle 17.png",
      rows: 2,
      cols: 1,
    },
  ];
  const [{ galleryData }, setAbout] = useState(require("../db/gallery.json"));

  const dispatch = useDispatch();

  const { galleryPageData , isLoading } = useSelector(state => state.galleryPage)
  useEffect(() => {
    const requestData = {
      body: { "jsonrpc": "2.0", "params": {} }
    };
    dispatch(galleryPageDataRequest({ ...requestData }));
  }, []);
  
  return isLoading?.loading ? <Loading /> : <GalleryTemplate galleryData={galleryData} itemData={itemData} galleryPageData={galleryPageData} />;
}
