import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModelBox from "../../Atoms/SharedComponent/Model";
import { galleryModel } from "../../services/modules/models/Actions";
import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function GalleryModel({ galleryPageData }) {
  const dispatch = useDispatch();
  const { isGalleryImgOpen, galleryId } = useSelector((state) => state.model);
  console.log(galleryId, "galleryId");
  const [{ galleryData }] = useState(require("../../db/gallery.json"));
  const [isActive, setIsActive] = useState(galleryId);
  const { mainDarkColorTwo, blueTwo } = useThemePalette();
  const [isCurrent, setIsCurrent] = useState(1);

  return (
    <>
      <ModelBox
        openVar={isGalleryImgOpen}
        top={"15px"}
        right="12px"
        maxWidth="100% !important"
        modelBorderRadius="20px"
        iconBgColor="rgba(0, 0, 0, 0.20)"
        closeFunc={() => {
          dispatch(galleryModel());
        }}
        padding="0"
        mt="0"
        dialogWidth="1000px"
        iconColor="white"
        bgcolor={mainDarkColorTwo}
        // dialogHeight='690px !improtant'
      >
        <CustomSlider
          slidesToShowXl={1}
          setIsActive={setIsActive}
          maxWidth="lg"
          className="gallery-slider"
          infinite={true}
          length={galleryPageData?.gallery?.length}
          containerPadding="0 !important"
          fade={true}
          galleryId={galleryId}
          setIsCurrent={setIsCurrent}
          containerClassName='gallery-slider'
        >
          {galleryPageData?.gallery?.map((data, i) => (
            <>
              {/* <picture> */}
                {/* {data?.image?.map((headerImg) =>
                                <source style={{ width: '100%' }} key={headerImg?.size} media={`(min-width:${headerImg?.size}px)`} srcSet={headerImg?.image_url} />
                            )} */}
                <img
                  width={"100%  !important"}
                  style={{
                    width: "100%",
                    height: "580px",
                  }}
                  src={
                    data?.image?.filter((img) => img?.size == 1920)[0]
                      ?.image_url
                  }
                  alt=""
                />
              {/* </picture> */}
              <Stack
                sx={{ mt: "17px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Box sx={{ width: "70%" }}>
                  <Typography
                    color={blueTwo}
                    fontSize="22px"
                    fontWeight="700"
                    textAlign="center"
                  >
                    {data?.title}
                  </Typography>
                </Box>
              </Stack>
            </>
          ))}
        </CustomSlider>
      </ModelBox>
    </>
  );
}
