import React from 'react'
import DetailsComponent from '../Atoms/SharedComponent/DetailsComponent'

export default function BlogDetailsTemplate({ data, product, moreData, moreBlogs ,mainBlog }) {
    return <>
        <DetailsComponent
            data={data}
            product={product}
            moreData={moreData}
            avatar
            moreBlogs={moreBlogs}
            mainBlog={mainBlog}
        />
    </>
}
