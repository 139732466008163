import React from 'react'
import ModelBox from '../../Atoms/SharedComponent/Model'
import { Box, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { iceCreamePicModel } from '../../services/modules/models/Actions'
import { useThemePalette } from "../../Theme/useThemePalatte";

const IceCreamModel = ({ img, title, type, productDetails }) => {
  const dispatch = useDispatch()
  const { isIceCreamePicOpen } = useSelector(state => state.model)
  const { blueThree, grayThree } = useThemePalette()
  return <>
    <ModelBox
      openVar={isIceCreamePicOpen}
      closeFunc={() => {
        dispatch(iceCreamePicModel());
      }}
      titleStyle={{
        fontSize: { lg: "35px", xs: "20px" },
        fontWeight: "800",
        mb: "15px",
        textAlign: "center",
        px: { lg: "50px", xs: "15px" },
      }}
      top='-20px'
      bgcolor='#CEEBF5' 
    >
      <Stack alignItems='center' justifyContent='center' >
        <Box
          component={'img'}
          alt='ice cream'
          src={img}
          mb='30px'
        />
        <Typography
          sx={{
            color: blueThree,
            fontFamily: "Kool Baby",
            fontSize: { lg:'50px', md:"40px"},
            textAlign: { md: "left", xs: "center" },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: grayThree,
            fontFamily: "Inter",
            fontSize: {lg:'30px', md: "20px", xs: ".65rem" },
            fontWeight: 500,
            mb: "20px",
            letterSpacing: "0.2px",
            textAlign: 'center',
          }}
        >
          {productDetails}
        </Typography>
      </Stack>
    </ModelBox>
  </>
}
export default IceCreamModel