import React, { useEffect } from "react";
import data from "../../db/products.json";
import ProductsTemplate from "../../Templates/Products/ProductsTemplate";
import { useDispatch, useSelector } from "react-redux";
import { productPageDataRequest } from "../../services/modules/products";
import Loading from "../../Atoms/SharedComponent/Loading";



export default function ProductsPage() {
  const CategoriesList = data.Categories;

  const dispatch = useDispatch()

  const { productPageData, isLoading } = useSelector(state => state.productPage)
  useEffect(() => {
    const requestData = {
      body: { "jsonrpc": "2.0", "params": {} }
    };
    dispatch(productPageDataRequest({ ...requestData }));
  }, []);

  return isLoading?.loading ? <Loading /> : <ProductsTemplate  productPageData={productPageData} />;
}
