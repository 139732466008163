import React, { Fragment, useEffect } from "react";
import SectionTitle from "../../Atoms/SectionTitle";
import ProductsContiner from "../../Organisms/Products/Container";
import ProductsFirstSection from "../../Organisms/Products/Header";
import { Box, Stack } from "@mui/material";
import ProductsDivider from "../../Assets/Images/products-divider.png";
import { useThemePalette } from "../../Theme/useThemePalatte";
import milkLoop from "../../Assets/Videos/milkLoop.webm";

export default function ProductsTemplate({ productPageData }) {
  const { mainLightColor } = useThemePalette();

  // Retrieve productType from localStorage
  const type = localStorage.getItem("type");
  const productType = localStorage.getItem("productType");

  const scrollTo =
    type || productType ? (type || productType).toUpperCase() : "";
  useEffect(() => {
    // Step 1: Scroll to the top initially
    window.scrollTo(0, 0);

    const scrollToElement = () => {
      const element = document.getElementById(scrollTo);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - 150,
          behavior: "smooth",
        });
      }
    };

    // Step 2: Delay the scroll to the target element
    if (scrollTo) {
      setTimeout(scrollToElement, 100);
    }
  }, [scrollTo]);

  setTimeout(() => {
    if (type) {
      localStorage.removeItem("type");
    } else if (productType) {
      localStorage.removeItem("productType");
    }
  }, 2000);
  return (
    <Stack sx={{ mb: { md: "100px" } }}>
      <ProductsFirstSection />
      <Box height="100%" mt={"-12%"} width="100%">
        <video muted width="100%" autoPlay loop>
          <source src={milkLoop} />
        </video>
      </Box>
      {productPageData?.map((category, index) => (
        <Fragment key={index}>
          <Stack
            id={category?.category_name?.toUpperCase()} // Corrected `id` assignment
            className={category?.category_name?.toLowerCase()}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: "62%",
            }}
          >
            <Stack textAlign="center">
              <SectionTitle
                color="#D5242F"
                title={category?.category_name}
                fontSize={{ md: "50px", xs: "22px" }}
                sx={{ letterSpacing: "4px" }}
                fontWeight="700"
                boxShadow={{
                  sm: "4px 4px rgba(255, 255, 255, 0.70)",
                  xs: "2px 2px 0px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Stack>
            <ProductsContiner subCategories={category?.sub_category} />
          </Stack>
          {index !== productPageData?.length - 1 && (
            <img
              src={ProductsDivider}
              className="wind"
              style={{ zIndex: 1 }}
              alt="cloud img"
            />
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
