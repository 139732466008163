import React, { useEffect, useState } from 'react'
import BlogDetailsTemplate from '../Templates/BlogDetailsTemplate'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blogDetailsPageDataRequest } from '../services/modules/blog';
import Loading from '../Atoms/SharedComponent/Loading';

export default function BlogDetailsPage() {
    const { id } = useParams()
    const [{ CardComponentData }, setCardComponentData] = useState(
        require("../db/blogs.json")
    );
    const blog = CardComponentData?.filter((element) => element?.id == id)[0]

    const dispatch = useDispatch();
    const moreBlogs = JSON.parse(localStorage?.getItem('blogList'))
    const mainBlog = JSON.parse(localStorage?.getItem('mainBlog'))
    const { blogDetailsPageData, isLoading } = useSelector(state => state.blogPage)
    useEffect(() => {
        const requestData = {
            body: {"jsonrpc": "2.0", "params": {}}
        };
        dispatch(blogDetailsPageDataRequest({ id, ...requestData }));
    }, [id]);

    return isLoading?.loading ? <Loading /> : <>
        <BlogDetailsTemplate
            // product={blog}
            data={blogDetailsPageData}
            moreData='blogs'
            moreDataa={moreBlogs}
            mainBlog={mainBlog}
        />
    </>
}
