import { Typography } from "@mui/material";
import React from "react";

export default function SectionTitle({
  title,
  fontSize,
  children,
  color = "#D5242F",
  boxShadow = "4px 2px 0px rgba(0, 0, 0, 0.15)",
  sx,
  textTransform="uppercase",
  textAlign='start',
  ...rest
}) {
  return (
    <Typography
      textTransform={textTransform}
      color={color}
      {...rest}
      fontSize={fontSize}
      variant="h2"
      textAlign={textAlign}
      sx={{ textShadow: boxShadow, ...sx }}
    >
      {children ? children : title}
    </Typography>
  );
}
