import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import CustomButton from "../../Atoms/CustomButton";
import SectionTitle from "../../Atoms/SectionTitle";
import { useThemePalette } from "../../Theme/useThemePalatte";

export default function SubcribtionForm() {
  const { mainDarkColor } = useThemePalette();
  return (
    <>
      <Stack justifyContent="center" sx={{ mb: { lg: '8rem' }, ml: { lg: '0 ', md: '5rem' }, mt: { lg: 0, md: '10px' } }} alignSelf="self-start">
        <Box>
          <SectionTitle
            title="WANT TO BE ALWAYS INFORMED?"
            fontSize={{ lg: "42px", md: '30px', sm: '25px', xs: '15px ' }}
            width={{ md: "341px", xs: '40%' }}
            color={mainDarkColor}
            fontWeight="700"
            lineHeight="1.5"
            boxShadow="3px 3px 0px rgba(255, 255, 255, .60)"
            mt={{ md: '0', xs: '13px' }}
          />
        </Box>
        <TextField
          sx={{
            mt: { lg: "50px", md: '30px', xs: '20px' },
            mb: { md: "32px", xs: '10px' },
            bgcolor: "white",
            borderRadius: "8px",
            borderColor: "none",
            border: "0px",
            width: { md: "90%", xs: '80%' },
            '& input': {
              padding: { md: '16.5px 14px', xs: '7px 5px' },
            },
            '& input::placeholder': {
              fontSize: { md: '18px', xs: '12px' }
            }

          }}
          placeholder="ENTER YOUR EMAIL"
        />
        <Box>

          <CustomButton width={{ md: '200px' }} title="send" xsMt='0' fontSize={{ md: "16px", xs: '12px' }} />
        </Box>
      </Stack>
    </>
  );
}
