import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import DescriptionComponent from "../Atoms/DescriptionComponent";
import SectionTitle from "../Atoms/SectionTitle";

export default function PagesContainer({
  bgImg,
  children,
  pt,
  backgroundSize,
  backgroundPosition,
  title,
  description,
  titleTextAlign,
  direction,
  descriptionWidth,
  descTextAlign,
  alignItems,
  titleColor = "#3C59A3",
  boxShadow,
  descriptionFontSize,
  descriptionPt,
  sx,
  mobileViewImg,
  ...rest
}) {
  return (
    <Box
      sx={{
        backgroundImage: { sm: `url(${bgImg})`, xs: `url(${mobileViewImg})` },
        backgroundRepeatY: "no-repeat !important",
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
        ...sx,
      }}
      pt={pt}
      {...rest}
    >
      <Stack
        alignItems={alignItems ? alignItems : "center"}
        direction={direction}
      >
        <Stack direction={{ sm: "column" }} alignItems="center">
          {title && (
            <SectionTitle
              textAlign={titleTextAlign ? titleTextAlign : "center"}
              lineHeight={{ md: "78.38px", xs: "normal" }}
              // width={descriptionWidth ? descriptionWidth : "658px"}
              fontSize={{ lg: "45px", md: "40px", xs: "20px" }}
              color={titleColor}
              boxShadow={boxShadow}
              sx={{ letterSpacing: "4px", fontWeight: { md: 400, xs: 700 } }}
            >
              {title}
            </SectionTitle>
          )}
          {description && (
            <DescriptionComponent
              descriptionPt={descriptionPt}
              descriptionWidth={descriptionWidth}
              descriptionFontSize={descriptionFontSize}
              textAlign={descTextAlign}
              description={description}
            />
          )}
        </Stack>
        {children}
      </Stack>
    </Box>
  );
}
