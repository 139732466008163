import { useThemePalette } from "../../Theme/useThemePalatte";
import milkLoop from "../../Assets/Videos/milkLoop.webm";
import { Box, Stack } from "@mui/material";
import CustomVideo from "../../Atoms/CustomVideo";

export default function Header({ header }) {
  const { mainLightColor } = useThemePalette();
  return (
    <>
      {header?.video && (
        <CustomVideo videoUrl={header?.video} style={{ marginTop: "7px" }} />
      )}
      {/* <CustomVideo videoUrl="https://www.youtube.com/watch?v=tQLgyUKYFfE" /> */}

      <Box height="100%" width="100%" position="relative" mt="-10%">
        <video
          muted // Ensure the video is muted
          width="100%"
          height="100%"
          autoPlay
          loop
          style={{ objectFit: "cover" }}
        >
          <source src={milkLoop} />
        </video>
      </Box>
    </>
  );
}
