import { Box } from "@mui/system";
import React from "react";
import youngManWithIceCream from "../../Assets/Images/youngManWithIceCream.png";
import girl from "../../Assets/Images/girl.png";
import SkeletonComponent from "../../Molecules/SkeletonComponent";
import SubcribtionForm from "../../Molecules/Subscribtion/Form";

export default function Subscribe() {
  return (
    <Box
    // sx={{ py: '10px' }}

    >
      <SkeletonComponent
        leftSide={
          <Box
            width={{ lg: "364.87px", md: '250px', xs: '160px' }}
            // height={{ sm: "507px" }}
            justifySelf={'start'}
          >
            <Box
              component='img'
              src={youngManWithIceCream}
              alt="youngManWithIceCream"
              // className="w-100"
              display={{ md: 'block', xs: 'none' }}
              sx={{
                mb: { lg: 0, md: '-74px' },
                width: { lg: '100%', md: '100%' }
              }}
            />
            <Box
              display={{ md: 'none', xs: 'block' }}
              component='img'
              src={girl}
              alt="youngManWithIceCream"
              className="w-100"
            />
          </Box>
        }
        RightSide={<SubcribtionForm />}
        rightSideWidth="427px"
        leftDuration="1.9"
        rightDuration="1.9"
        pt={{ lg: "8rem", sm: '0', xs: '0' }}
        alignItems={{ md: "center", xs: 'start' }}
        rightSideAlignSelf="self-start"
      />
    </Box>
  );
}
