import React, { useEffect, useState } from 'react'
import AboutUsTemplate from '../Templates/AboutUsTemplate'
import { useDispatch, useSelector } from 'react-redux';
import { aboutPageDataRequest } from '../services/modules/about';
import Loading from '../Atoms/SharedComponent/Loading';

export default function AboutUsPage() {
    const dispatch = useDispatch();
    const { aboutPageData, isLoading } = useSelector(state => state.aboutPage)
    useEffect(() => {
        const requestData = {
            body: { "jsonrpc": "2.0", "params": {} }
        };
        dispatch(aboutPageDataRequest({ ...requestData }));
    }, []);

    return isLoading?.loading ? <Loading /> : <>
        <AboutUsTemplate aboutPageData={aboutPageData} />
    </>
}
