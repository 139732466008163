import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SectionTitle from '../Atoms/SectionTitle';
import { useThemePalette } from '../Theme/useThemePalatte';
import clockIcon from "../Assets/Images/clockIconDark.svg";
import RecipesCard from '../Atoms/SharedComponent/RecipesCard';
import CustomButton from '../Atoms/CustomButton';
import RecipesDetailsTemplate from '../Templates/RecipesDetailsTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { recipeDetailsPageDataRequest } from '../services/modules/recipes';
import Loading from '../Atoms/SharedComponent/Loading';

export default function RecipesDetailsPage() {
    const { id } = useParams()
    const [{ CardComponentData }, setCardComponentData] = useState(
        require("../db/our-recipes.json")
    );
    const recipe = CardComponentData?.filter((element) => element?.id == id)[0]

    const moreRecipes = JSON.parse(localStorage?.getItem('recipeList'))
    const dispatch = useDispatch();
    const { recipesDetailsPageData, isLoading } = useSelector(state => state?.recipePage)

    useEffect(() => {
        const requestData = {
            body: { jsonrpc: "2.0", params: {} },
        };
        dispatch(recipeDetailsPageDataRequest({ id, ...requestData }));
    }, [id]);
    
    return isLoading?.loading ? <Loading /> : <>
        <RecipesDetailsTemplate
            product={recipe}
            data={recipesDetailsPageData}
            moreData={'recipes'}
            // recipesDetailsPageData={recipesDetailsPageData}
            moreRecipes={moreRecipes}
        />
    </>
}
