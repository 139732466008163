import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../../Atoms/SectionTitle";
import { useThemePalette } from "../../Theme/useThemePalatte";
import CustomSlider from "../../Atoms/SharedComponent/CustomSlider";
import AboutUsSliderCard from "../../Molecules/AboutUs/AboutUsSliderCard";
import OurValuesModel from "./OurValuesModel";
import { fadeInLeft, fadeInRight } from "../Home/MotionDirections";
import Motion from "../../Atoms/SharedComponent/Motion";

export default function AboutUsDetails({ aboutPageData }) {
  const [{ aboutUsDetails, ourValues }, setAboutUsDetails] = useState(
    require("../../db/about-us.json")
  );
  const [isActive, setIsActive] = useState(1);
  const [isCurrent, setIsCurrent] = useState(1);
  const [isHoverd, setIsHovers] = useState(false);

  const { mainDarkColorTwo, grayThree, mainDarkColor } = useThemePalette();

  return (
    <>
      <Container maxWidth="xl" sx={{ pt: "4rem" }}>
        <Stack rowGap={{ md: "80px", xs: "32px" }}>
          {aboutPageData?.about_us_details?.map((data, i) => (
            <Stack
              bgcolor={{ md: "transparent", xs: "#CEEBF5" }}
              direction={{ md: "row", xs: "column-reverse" }}
              sx={{
                position: { md: "relative" },
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ width: { md: "57%", xs: "100%" } }}>
                <Motion
                  {...fadeInLeft({ width: "100%", i, delay: i / 4 })}
                  key={i}
                >
                  <Box
                    sx={{
                      bgcolor: mainDarkColorTwo,
                      // pb: '20px',
                      pt: { md: "60px", xs: "20px" },
                      width: "100%",
                      pl: { md: "40px" },
                      pr: { md: "164px" },
                      borderRadius: "8px",
                      minHeight: { md: "262px" },
                      pb: "30px",
                    }}
                  >
                    <SectionTitle
                      boxShadow="none"
                      color={mainDarkColor}
                      fontSize={{ lg: "50px", md: "40px", xs: "20px" }}
                      title={data?.title}
                      sx={{
                        textAlign: { md: "start", xs: "center" },
                        mx: { md: "0", xs: "20px" },
                        mb: "15px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: grayThree,
                        fontSize: { lg: "25px", md: "22px", xs: "15px" },
                        fontFamily: "Inter",
                        fontWeight: "600",
                        letterSpacing: "0.2px",
                        lineHeight: "42px",
                        width: { md: "85%" },
                        // pt: '20px',
                        textAlign: { md: "start", xs: "center" },
                        mx: { md: "0", xs: "20px" },
                      }}
                    >
                      {data?.description}
                    </Typography>
                  </Box>
                </Motion>
              </Box>
              <Box sx={{ width: { md: "65%", xs: "100%" } }}>
                <Motion
                  {...fadeInRight({ width: "100%", i, delay: i / 3 })}
                  key={i}
                >
                  <Box
                    py={{ md: "1rem" }}
                    alignSelf="end"
                    sx={{
                      position: { md: "absolute" },
                      right: "0",
                      top: { md: "22px" },
                      width: "100%",
                    }}
                  >
                    <picture>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={
                          data?.image?.filter((img) => img?.size == 1920)[0]
                            ?.image_url
                        }
                        alt=""
                      />
                    </picture>
                  </Box>
                </Motion>
              </Box>
            </Stack>
          ))}
          {/* ------------------------------------------ Our values ----------------------------------------------- */}
          <Stack
            sx={{
              bgcolor: mainDarkColorTwo,
              borderRadius: "8px",
              pb: "40px",
              pt: { md: "60px", xs: "24px" },
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <SectionTitle
              sx={{ margin: "auto", mb: { xs: "30px" } }}
              boxShadow="none"
              color={mainDarkColor}
              fontSize={{ md: "50px", xs: "20px" }}
              title={ourValues?.title}
            />
            <CustomSlider
              slidesToShowXl={3}
              slideToShowMd={2}
              setIsActive={setIsActive}
              length={aboutPageData?.values?.length - 1}
              className="our-values"
              infinite={aboutPageData?.values?.length > 3 ? true : false}
              xsInfinite={aboutPageData?.values?.length > 1 ? true : false}
              maxWidth={"xl"}
              setIsCurrent={setIsCurrent}
              isHoverd={isHoverd}
            >
              {aboutPageData?.values?.map((data, i) => (
                <Box
                  key={i}
                  sx={{
                    transform:
                      isCurrent == i
                        ? "scale(0.9)"
                        : isActive == i
                        ? "scale(1.25)"
                        : "scale(0.9)",
                    transition: "0.5s",
                  }}
                  onMouseOver={(_) => setIsHovers(true)}
                  onMouseLeave={(_) => setIsHovers(false)}
                >
                  <AboutUsSliderCard
                    data={data}
                    isActive={isActive}
                    descriptionFontSize={{ md: "20px", xs: "18px" }}
                    isCurrent={isCurrent}
                    index={i}
                  />
                </Box>
              ))}
            </CustomSlider>
          </Stack>
        </Stack>

        <OurValuesModel aboutPageData={aboutPageData} />
      </Container>
    </>
  );
}
