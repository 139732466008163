import { ThemeProvider, Container, Box } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./Atoms/NotFound";
import HomePage from "./Pages/HomePage";
import { theme } from "./Theme/ThemePalette";
import OurRecipesPage from "./Pages/OurRecipesPage";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import About from "./Organisms/Home/About";
import ProductDetailPage from "./Pages/Products/ProductsDetailsPage";
import Careers from "./Pages/CareersPage";
import RecipesDetailsPage from "./Pages/RecipesDetails.page";
import BlogDetailsPage from "./Pages/BlogDetailsPage";
import Layout from "./Organisms/Layout/Layout";
import ProductsPage from "./Pages/Products/ProductsPage";
import GalleryPage from "./Pages/GalleryPage";
import BlogPage from "./Pages/BlogPage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { store } from "./services/store";
import { Provider as ReduxProvider } from "react-redux";
import { ScrollToTop } from "./Atoms/ScrollToTop/ScrollToTop";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ScrollToTop />
          {/* <Container
            maxWidth={"lg"}
            disableGutters
            sx={{ overflow: "hidden", marginTop: "-20px" }}
          > */}
          <Box maxWidth="100%" sx={{ overflow: "hidden" }}>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/recipes" element={<OurRecipesPage />} />
                <Route path="/contact" element={<ContactUsPage />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/product" element={<ProductsPage />} />
                <Route
                  path="/product-details/:id"
                  element={<ProductDetailPage />}
                />
                <Route
                  path="/recipes-details/:id"
                  element={<RecipesDetailsPage />}
                />
                <Route path="/blog-details/:id" element={<BlogDetailsPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/career" element={<Careers />} />
                {/* <Route path="*" element={<NotFound />} /> */}
                <Route path="*" element={<HomePage />} />
              </Routes>
            </Layout>
          </Box>
          {/* </Container> */}
        </LocalizationProvider>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
