import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useNavigate } from "react-router-dom";

export default function FooterText({ lists }) {
  const { greenText, grayText } = useThemePalette();
  const navigate = useNavigate()
  return (
    <>
      {lists.map((list, i) => (
        <Grid
          alignItems='center'
          key={i}
          item
          xl={list.xlSize}
          lg={list.lgSize}
          md={list.mdSize}
        // sx={{ justifyContent: 'center !important', alignItems: 'center !imprtant' , display:'flex' , flexDirection:{md:'row' , xs:'column'} }}
        >
          <Stack alignItems={{ md: 'start', xs: 'center' }} key={i} item xl={list.xlSize} lg={list.lgSize} md={list.mdSize}>
            <Typography
              color="white"
              fontSize={{ lg: "20px", md: '15px ', xs: '16px' }}
              pb={{ md: "16px", xs: '8px' }}
              fontWeight="600"
              // textAlign={{ md: 'start', xs: 'center' }}
              href={list?.path}
              target="_blank"
            // onClick={_ => navigate(list?.path)}
            >
              {/* <Button
                  href={list?.path}
                  target="_blank"
                >
                  {country?.whatsapp?.number}
                </Button> */}
              {list.title}
            </Typography>

            <Button
              href={list?.path}
              target="_blank"
              sx={{ padding: '0', cursor: list?.path ? 'pointer' : 'auto' }}
              disableRipple
              disableElevation
            >
              <Typography
                textAlign={{ md: 'start', xs: 'center' }}
                fontSize={{ lg: "16px", md: '13px', xs: '14px' }}
                color="white"
                pb={{ md: "38px", xs: '20px' }}
              >

                {list.address}
              </Typography>
            </Button>
            {list.icons}
          </Stack>
        </Grid>
      ))}
    </>
  );
}
