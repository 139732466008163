import React, { useState } from "react";
import ProductsList from "../../Molecules/products/ProductsList";
import SubCategory from "../../Molecules/products/components/SubCategory";
import { Box, Stack } from "@mui/material";

export default function Container({ subCategories, CategoriesList }) {
  const indexRecieved = (indexRec) => {
    setBtnIndex(indexRec); //make btnIndex with the number of index i clicked
  };
  const [isActive, setIsActive] = useState(1);
  const [btnIndex, setBtnIndex] = useState(0);
  const [isCurrent, setIsCurrent] = useState();

  return (
    <Stack spacing={3} width="100%" alignItems="center">
      <SubCategory
        SubCategories={subCategories}
        ReciveIndex={indexRecieved}
        setCardActive={setIsActive}
      />
      <ProductsList
        SubCategories={subCategories}
        openIndex={1}
        OpenBtnIndex={btnIndex}
        setIsActive={setIsActive}
        isActive={isActive}
        CategoriesList={CategoriesList}
        isCurrent={isCurrent}
        setIsCurrent={setIsCurrent}
      />
    </Stack>
  );
}
