import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SectionTitle from "../../Atoms/SectionTitle";
import { useThemePalette } from "../../Theme/useThemePalatte";
import { useDispatch } from "react-redux";
import { valuesCardModel } from "../../services/modules/models/Actions";

export default function AboutUsSliderCard({
  data,
  descriptionFontSize = "18px",
  isActive,
  isCurrent,
  index,
}) {
  const { grayThree, mainDarkColor } = useThemePalette();
  const dispatch = useDispatch();

  return (
    <>
      <Stack
        sx={{
          bgcolor: data?.background_color,
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={(_) => {
          dispatch(valuesCardModel());
          localStorage.setItem("cardId", data?.id);
        }}
      >
        <picture>
          {data?.image && data?.image.length > 0 && (
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                // maxHeight: "286px"
              }}
              src={data?.image?.find((img) => img?.size === 1920)?.image_url}
              alt=""
            />
          )}
        </picture>
        <Stack
          sx={{
            py: "24px",
            px: "26px",
            borderRadius: "8px",
            pb:
            "40px",
            // transition: "0.1s",
          }}
        >
          <SectionTitle
            sx={{ margin: "auto" }}
            boxShadow="none"
            color={mainDarkColor}
            fontSize={{ md: "27px", xs: "17px" }}
            title={data?.title?.split(" ")[0]}
            textAlign="center"
          />
          {localStorage.getItem("cardId") ? (
            <Typography
              sx={{
                color: grayThree,
                fontSize: descriptionFontSize,
                fontFamily: "Inter",
                fontWeight: "500",
                textAlign: "center",
                mt: "8px",
              }}
            >
              {data?.description}
            </Typography>
          ) : data?.description?.length > 60 ? (
            <Typography
              className="line-clamp"
              sx={{
                color: grayThree,
                fontSize: descriptionFontSize,
                fontFamily: "Inter",
                fontWeight: "500",
                textAlign: "center",
                mt: "8px",
                "-webkit-line-clamp:": "2 !important",
                lineClamp: 3,
              }}
            >
              {`${data?.description?.substring(0, 60)}`}
              <span
                style={{
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                ...
              </span>
            </Typography>
          ) : (
            <Typography
              sx={{
                color: grayThree,
                fontSize: descriptionFontSize,
                fontFamily: "Inter",
                fontWeight: "500",
                textAlign: "center",
                mt: "8px",
              }}
            >
              {data?.description}
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
}
