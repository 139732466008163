import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FooterText from "../../Molecules/Footer/FooterText";
import FooterIcons from "../../Molecules/Footer/FooterIcons";
import footertBg from "../../Assets/Images/footertBg.svg";
import FooterBgMobile from "../../Assets/Images/FooterBgMobile.png";
import footerImg from "../../Assets/Images/footerImg.png";
import Copyright from "../../Molecules/Footer/Copyright";
import PagesContainer from "../../Molecules/PagesContainer";

export default function Footer({ contactUsPageData }) {
  const lists = [
    {
      title: "ADDRESS",
      address: contactUsPageData?.address,
      // icons: contactUsPageData?.social_media && (
      //   <FooterIcons socialMedia={contactUsPageData?.social_media} />
      // ),
      xlSize: 2.6,
      lgSize: 3.5,
      mdSize: 2.1,
    },
    {
      title: "EMAIL",
      address: contactUsPageData?.email,
      path: `mailto:${contactUsPageData?.email}`,
      icons: contactUsPageData?.social_media && (
        <FooterIcons socialMedia={contactUsPageData?.social_media} />
      ),
      xlSize: 1,
      lgSize: 1.5,
      mdSize: 1,
    },

    {
      title: "WORKING TIMERS",
      address: (
        <>
          <span style={{ marginRight: "30px" }}>10 AM - 10 PM</span>
          <span component={"span"}>Open daily</span>
        </>
      ),
      xlSize: 3,
      lgSize: 2,
      mdSize: 2,
    },
  ];

  return (
    <>
      <Box marginTop={{ md: "100px", xs: "30px" }}>
        <PagesContainer
          alignItems="space-between"
          bgImg={footertBg}
          mobileViewImg={FooterBgMobile}
          backgroundSize="cover"
        >
          <Box
            sx={{
              height: { lg: "190px", md: "120px" },
              pt: { md: "79px", xs: "40px" },
              pb: { md: "79px" },
              px: { md: "43px" },
              position: { xs: "static", md: "relative" },
            }}
          >
            <Grid
              container
              direction={{ md: "row", xs: "column" }}
              gap={{ xs: "10px", lg: "40px", md: "21px" }}
            >
              <FooterText lists={lists} />
              <Box
                sx={{
                  position: { xs: "static", md: "absolute" },
                  right: { xl: "80px", md: "40px" },
                  // bottom: "-24px",
                  top: { xl: "-53px", lg: "-20px", md: "-53px" , xs:'-53px'},
                  // display: { xs: "none", lg: "flex" },
                }}
              >
                <Box
                  sx={{ margin: "auto" }}
                  width={{
                    xl: "363.97px",
                    lg: "320px",
                    md: "300px",
                    xs: "178px",
                  }}
                >
                  {/* <img src={contactUsPageData?.footer_image[1]?.image_url} alt="" className="w-100" /> */}

                  <picture
                    style={{ display: "flex", width: "100%", height: "100%" }}
                  >
                    {contactUsPageData?.footer_image?.map((footerImg) => (
                      <source
                        key={footerImg?.size}
                        media={`(min-width:${footerImg?.size}px)`}
                        srcSet={footerImg?.image_url}
                      />
                    ))}
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={
                        contactUsPageData?.footer_image?.filter(
                          (img) => img?.size == 1920
                        )[0]?.image_url
                      }
                      alt=""
                    />
                  </picture>
                </Box>
              </Box>
            </Grid>
          </Box>
        </PagesContainer>
      </Box>
      <Copyright />
    </>
  );
}
