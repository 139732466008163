import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RecipesCard from "../../Atoms/SharedComponent/RecipesCard";
import PagesContainer from "../../Molecules/PagesContainer";
import PagesSkeleton from "../../Molecules/PagesSkeleton";
import { Container } from "@mui/material";

export default function OurRecipes({ recipesPageData }) {
  const [{ CardComponentData }, setCardComponentData] = useState(
    require("../../db/our-recipes.json")
  );
  const navigate = useNavigate();

  return (
    <>
      <PagesSkeleton title="OUR RECIPES" />
      <PagesContainer
        pb={{ lg: "7rem", xs: "1rem" }}
        pt={{ lg: 0, xs: "3rem" }}
        title="YOU CAN MAKE WHAT YOU WANT BY OUR PRODUCTS"
        boxShadow="none"
        titleColor="#264C9E"
        descriptionWidth={{ md: "85%", xs: "97%" }}
        descriptionFontSize={{ lg: "30px", md: "20px", xs: "13px" }}
        descriptionPt={{ md: "2.25rem", xs: "1rem" }}
        description="Each and every production step is strictly checked to ensure compliance with hygiene, process and recipe requirements. Fusteka is certified in accordance with FSSC 22000 – a food safety standard that goes far beyond the stringent legal requirements."
      >
        <Box maxWidth="90%">
          <Stack
            sx={{ mt: "40px"}}
            direction="row"
            gap="40px"
            rowGap={{ md: "110px", xs: "20px" }}
            justifyContent={{ xs: "start" }}
            flexWrap="wrap"
          >
            {recipesPageData.map((data, i) => (
              <Box
                onClick={(_) => navigate(`/recipes-details/${data?.id}`)}
                key={data?.id}
                sx={{
                  cursor: "pointer",
                  width: { lg: "468px", sm: "47%", xs: "100%" },
                }}
              >
                <RecipesCard
                  data={data}
                  CardComponentData={recipesPageData}
                  i={i}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      </PagesContainer>
    </>
  );
}
