import React, { useEffect, useState } from "react";
import data from "../../db/products.json";
import { useParams } from "react-router-dom";
import ProductDetailTemplate from "../../Templates/Products/ProductDetailTemplate";
import { useDispatch, useSelector } from "react-redux";
import { productDetailsPageDataRequest } from "../../services/modules/products";

export default function ProductDetailPage() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const CategoriesList = data.Categories;
  const { productDetailsPageData, isLoading } = useSelector(state => state?.productPage)
  // console.log(productDetailsPageData, 'productDetailsPageData');

  useEffect(() => {
    const requestData = {
      body: { "jsonrpc": "2.0", "params": {} }
    };
    dispatch(productDetailsPageDataRequest({ id, ...requestData }))
  }, [id]);

  return isLoading?.loading ? <loading /> : <ProductDetailTemplate productDetailsPageData={productDetailsPageData} />;
}
