import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@mui/material";
import { SampleNextArrow, SamplePrevArrow } from "../../Molecules/SliderArrows";

export default function CustomSlider({
  children,
  setIsActive,
  className,
  maxWidth,
  infinite = false,
  length,
  containerPadding,
  galleryId,
  xsInfinite,
  slideToShowMd = 1,
  setIsCurrent,
  slidesToShowXl,
  isHoverd,
  setActiveSlides,
  containerClassName,
  arrows = true,
}) {
  const settings = {
    infinite: infinite,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnFocus: isHoverd || false,
    // pauseOnHover: isHoverd || false,
    slidesToShow: slidesToShowXl || 3,
    speed: 500,
    cssEase: "ease-in-out",
    // lazyLoad: true,
    // centerMode:true,
    nextArrow: arrows ? <SampleNextArrow /> : false,
    prevArrow: arrows ? <SamplePrevArrow /> : false,
    beforeChange(current, next) {
      if (current == length) {
        setIsActive(() => 0);
        setIsCurrent(() => 0);
      } else {
        setIsCurrent(() => current + 1);
        setIsActive(() => current + 1);
      }
    },
    afterChange(current) {
      setTimeout(() => {
        if (current == length) {
          setIsActive(() => 0);
        } else {
          setIsActive(() => current + 1);
        }
      }, 300);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: slideToShowMd,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: xsInfinite,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          infinite: xsInfinite,
        },
      },
    ],
  };
  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{ margin: 0, padding: containerPadding }}
        className={containerClassName && containerClassName}
      >
        <Slider
          {...settings}
          className={className}
          initialSlide={galleryId || 0}
        >
          {children}
        </Slider>
      </Container>
    </>
  );
}
