import Types from "./Types";

export const CareerDataSuccess = (payload) => ({
  type: Types.CAREER_PAGE_DATA_SUCCESS,
  payload,
});

export const CareerDataLoading = (payload) => ({
  type: Types.CAREER_PAGE_DATA_LOADING,
  payload,
});
