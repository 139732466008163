import React, { useEffect } from "react";
import CareersTemplate from "../Templates/CareersTemplate";
import { useDispatch, useSelector } from "react-redux";
import { careerPageDataRequest } from "../services/modules/career";
import Loading from "../Atoms/SharedComponent/Loading";

export default function CareersPage() {
  const dispatch = useDispatch();

  const { careerPageData, isLoading } = useSelector(state => state.careerPage)
  useEffect(() => {
    const requestData = {
      body: {"jsonrpc": "2.0", "params": {}}
    };
    dispatch(careerPageDataRequest({ ...requestData }));
  }, []);
  return isLoading?.loading ? <Loading /> : <CareersTemplate careerPageData={careerPageData} />;
}
