import { Stack } from "@mui/system";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import { Box, Button } from "@mui/material";
import TikTok from "../../Assets/Images/icons/TikTok.png";

export default function FooterIcons({ socialMedia }) {
  const icons = [
    { href: "https://www.facebook.com/fusteka/", icon: FacebookOutlinedIcon },
    { href: "https://www.youtube.com/@fustekaicecream", icon: YouTubeIcon },
    { href: "https://wa.me/9647803922492", icon: WhatsAppIcon },
    {
      href: "https://www.instagram.com/fusteka_ice_cream/",
      icon: InstagramIcon,
    },
    {
      href: "https://www.tiktok.com/@fusteka_ice_cream",
      icon: TikTok,
    },
  ];

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1} // Add spacing between icons
    >
      {icons.map((data, i) => (
        <Button
          key={i}
          href={data.href}
          target="_blank"
          sx={{ minWidth: 0, padding: 0 }}
        >
          {typeof data.icon === "string" ? (
            <Box
              component="img"
              src={data.icon}
              sx={{
                width: "40px",
                height: "40px",
                padding: "3px",
                backgroundColor: "white",
                borderRadius: "50%",
                color: "#3C59A3",
              }}
            />
          ) : (
            <data.icon
              style={{
                color: "#3C59A3",
                fontSize: "40px",
                padding: "3px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            />
          )}
        </Button>
      ))}
    </Stack>
  );
}
