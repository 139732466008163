import Types from "./Types";

export const BlogDataSuccess = (payload) => ({
  type: Types.BLOG_PAGE_DATA_SUCCESS,
  payload,
});

export const BlogDataLoading = (payload) => ({
  type: Types.BLOG_PAGE_DATA_LOADING,
  payload,
});

export const BlogdDetailsDataSuccess = (payload) => ({
  type: Types.BLOG_DETAILS_PAGE_DATA_SUCCESS,
  payload,
});
