import React, { useState } from "react";
import AboutUsFactory from "../../Molecules/AboutUs/AboutUsFactory";
import PagesSkeleton from "../../Molecules/PagesSkeleton";
import AboutUsDetails from "./AboutUsDetails";


export default function AboutUs({ aboutPageData }) {
  const [isPlayed, setIsPlayed] = useState({ state: false, id: null })
  const playVideo = (id) => {
    document.querySelector(`#${id}`)?.play()
  }

  return (
    <>
      <PagesSkeleton title="ABOUT US" />
      <AboutUsFactory aboutPageData={aboutPageData} playVideo={playVideo} isPlayed={isPlayed} setIsPlayed={setIsPlayed} />
      <AboutUsDetails aboutPageData={aboutPageData} />
    </>
  );
}
