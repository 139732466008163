import { combineReducers } from "redux";
import modelReducer from "./modules/models/Reducer";
import homePageReducer from "./modules/home/Reducer";
import aboutPageReducer from "./modules/about/Reducer";
import recipePageReducer from "./modules/recipes/Reducer";
import galleryPageReducer from "./modules/gallery/Reducer";
import blogPageReducer from "./modules/blog/Reducer";
import productPageReducer from "./modules/products/Reducer";
import careerPageReducer from "./modules/career/Reducer";
import contactUsPageReducer from "./modules/contactUs/Reducer";

const rootReducer = combineReducers({
  model: modelReducer,
  homePage: homePageReducer,
  aboutPage: aboutPageReducer,
  recipePage: recipePageReducer,
  galleryPage: galleryPageReducer,
  blogPage: blogPageReducer,
  productPage: productPageReducer,
  careerPage: careerPageReducer,
  contactUs: contactUsPageReducer
});

export default rootReducer;
