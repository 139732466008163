import React, { useEffect } from 'react'
import HomeTemplate from '../Templates/HomeTemplate'
import { homePageDataRequest } from '../services/modules/home';
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from '../Atoms/SharedComponent/Loading';
export default function HomePage() {
    const dispatch = useDispatch();

    const { homePageData, isLoading } = useSelector(state => state.homePage)
    useEffect(() => {
        const requestData = {
            body: { "jsonrpc": "2.0", "params": {} }
        };
        dispatch(homePageDataRequest({ ...requestData }));
    }, []);
    return isLoading?.loading ? <Loading /> : <HomeTemplate homePageData={homePageData} />
}
