import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import btnBg from "../Assets/Images/btnBg1.png";
import btnMobieBg from "../Assets/Images/btnMobieBg.svg";

export default function CustomButton({
  title,
  path,
  type,
  isActive,
  bg,
  fontSize,
  click,
  xsMt = '48px',
  mt,
  width = { md: "200px", xs: '150px' }
}) {
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (path) {
      // e.preventDefault();
      navigate(path);
    }
  };
  return (
    <>
      <Button
        sx={{
          ...(isActive ? { backgroundImage: { md: `url(${bg})`, xs: `url(${btnMobieBg})` } } : { backgroundImage: `url(${btnBg})` }),
          width: width,
          backgroundRepeat: "no-repeat",
          backgroundSize: 'cover',
          height: { md: "60px" },
          color: "white",
          borderRadius: 0,
          mt: { lg: 'auto', md: mt, xs: xsMt },
          // px:'40px',
          fontSize: fontSize ? fontSize : "20px",
        }}
        type={type}
        // {...rest}
        onClick={(e) => path ? handleClick(path) : click}
      >
        {title}
      </Button>
    </>
  );
}
